import { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { Login } from "../Login/Login";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Loading } from "../../components/Loading/Loading";


import AllActions from "../../store/actions";
import { PdfViewer } from "../PdfViewer/PdfViewer";
import { getEnvSettings } from "../../config/config";

import { Avatar, ConfigProvider, Dropdown, Menu } from "antd";
import { Schedule } from "../Schedule/Schedule";
import { Project } from "./../Project/Project";
import { Projects } from "./../Projects/Projects";
import { MapPage } from "../MapPage/MapPage";
import { Header } from "../../components/Header/Header";

import antdLocale from 'antd/locale/fi_FI';

import dayjs from 'dayjs';
import 'dayjs/locale/fi';
import { Searchbar } from "../../components/Searchbar/Searchbar";
dayjs.locale('fi');


function App() {
  const dispatch = useDispatch();
  const envConfig = getEnvSettings();

  const { userLoaded, loggedIn, showSearch } = useSelector((state) => state.currentUser);


  useEffect(() => {
    document.title = `YBT Oy - ${envConfig.title}`; // Quick solution
  }, []);



  useEffect(() => {
    const updAuth = async (text) => {
      await localStorage.setItem(
        "USER_AUTH",
        JSON.stringify({
          accessToken: text,
          refreshToken: null,
          authenticated: true,
        })
      );
    };

    const urlParams = new URLSearchParams(window.location.search);
    let authParam = urlParams.get("auth");
    console.log("TESTI5", authParam, urlParams);

    if (authParam) {
      console.log("AUTH LÖYTYY");
      updAuth(authParam);
    }
    dispatch(AllActions.userActions.loadUser());
  }, []);



  // Kuunnellaan näppäimistön painalluksia
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Tarkistetaan onko Ctrl + F painettu
      if (event.ctrlKey && event.key === 'f') {
        event.preventDefault(); // Estää selaimen oman haun
        dispatch(AllActions.userActions.updateShowSearch(showSearch));
      }
    };

    // Lisää keydown-tapahtuman kuuntelija
    window.addEventListener('keydown', handleKeyDown);

    // Siivoa kuuntelija komponentin poistuessa
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showSearch]);



  
  // console.log("ROOTNAV:", userLoaded, loggedIn);
  if (!userLoaded) {
    return <Loading />;
  }

  if (!loggedIn) {
    return <Login />;
  }


  return (
    <div className="App">
      <ConfigProvider locale={antdLocale}>

        <BrowserRouter>
          <Header />
          <Searchbar /> 
          {/* {showSearch ? <Searchbar /> : <></>} */}

          <Routes>
            <Route path="/" element={<Projects />} />
            {envConfig.showSchedule && <Route path="/schedule/" element={<Schedule />} />}
            {envConfig.showMap && <Route path="/map/" element={<MapPage />} />}
            <Route path="/projects/" element={<Projects />} />
            <Route path="/project/:id" element={<Project />} />
            <Route path="/pdfviewer/:id" element={<PdfViewer />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}

export default App;
