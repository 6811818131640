import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Modal from "react-modal";

import { Input } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../components/Translate";


const ModalHeader = ({ closeModal, header, user }) => {
  return (
    <div>
      <div className="proj-modalHeaderRow">
        <p className="proj-modalHeader font-dm-bold">{header}</p>
        <CloseOutlined
          className="proj-modalHeaderClose"
          onClick={() => closeModal()}
        />
      </div>
      <p className="proj-modalHeaderUser font-dm-medium">{user}</p>
    </div>
  );
};

const SaveBtn = ({ onClick }) => {
  const { locale } = useTranslate();

  return (
    <div className="proj-saveButtonCont">
      <button className="proj-saveButton font-dm-regular" onClick={onClick}>
        {locale.save}
      </button>
    </div>
  );
};

export const EditAddressModal = ({ isOpen, closeModal, data, saveFunc }) => {
  console.log("EditAddressModal data", data);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);

  const { locale } = useTranslate();

  useEffect(() => {
    console.log("isOpen updated", isOpen);
    if (isOpen) {
      setAddress1(data.address1)
      setAddress2(data.address2)
    } else {
      setAddress1(null)
      setAddress2(null)
    }

  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        className="workmodalBase"
        style={{
          overlay: {
            zIndex: 100,
            backgroundColor: "rgba(70, 70, 70, 0.5)",
          },
        }}
        ariaHideApp={false}
      >
        <div className="proj-modalContainer">
          <div className="proj-modal">
            <ModalHeader
              closeModal={() => closeModal()}
              header={"Muokkaa toimitusosoitetta"}
            />

            <p className="font-dm-medium label">{"Osoite"}</p>
            <Input
              value={address1}
              onChange={(e) => {
                setAddress1(e.target.value)
                console.log("onChange", e.target.value)
              }}
            />

            <p className="font-dm-medium label">{"Postiosoite"}</p>
            <Input
              value={address2}
              onChange={(e) => {
                setAddress2(e.target.value)
                console.log("onChange", e.target.value)
              }}
            />

            <SaveBtn onClick={() => {
              closeModal()
              saveFunc(address1, address2)
            }
            } />
          </div>
        </div>
      </Modal>
    </>
  );
};
