import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Schedule.css";

import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders
} from "react-calendar-timeline";

// make sure you include the timeline stylesheet or the timeline will not be styled
import moment from "moment";
import "moment/locale/fi";
import "react-calendar-timeline/lib/Timeline.css";
import {
  getParsedDate,
  getParsedDateShort
} from "../../components/globalFunctions";
import { AddWorkModal } from "./AddWorkModal/AddWorkModal";

import {
  DownOutlined,
  PlusCircleOutlined,
  UpOutlined
} from "@ant-design/icons";
import { drawLine, DrawTooltip } from "./ScheduleSharedFunc";

export const WorkersView = ({
  usersData,
  getUsersData,
  groupsOpen,
  setGroupsOpen,
  handleBtnOpenAll,
  btnAllOpen,
  part
}) => {
  const TODAY = moment();
  const DAY = 1000 * 60 * 60 * 24;
  const WEEK = DAY * 7;
  const MONTH = WEEK * 4;

  const MIN_ZOOM = WEEK * 3;
  const MAX_ZOOM = MONTH * 6;

  // const [isLoading, setisLoading] = useState(false);

  const [userWorks, setUserWorks] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [itemsData, setItemsData] = useState([]);

  const { selectedFactory } = useSelector((state) => state.currentUser.user);

  //Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});


  // const [groupsOpen, setGroupsOpen] = useState({});


  console.log('usersData', usersData)

  console.log('part', part)


  useEffect(() => {
    console.log("groupsOpen updated", groupsOpen);
  }, [groupsOpen]);




  const getRowData = (row) => {
    return {
      workId: row.Tyokohdenro,
      rowId: row.KirjausId,
      data: {
        name: row.Tyokohde,
        id: row.Tyokohdenro,
        elementgroup: row.ElementtiRyhma,
        table: row.Poyta,
        percent: row.KuormitusOsuus,
        kpl: row.ValmistamattaKpl,
        multiplier: row.HenkilokohtainenKerroin,
        pvmReady: null,
      },
    };
  };

  //Tarkistus onko lomat tms peräkkäin
  const isConsecutiveVacation = (lastVacationEnd, currentVacationStart) => {
    let lastVEnd = getParsedDate(moment(lastVacationEnd).add(1, 'days'));
    let currVStart = getParsedDate(moment(currentVacationStart));
    return lastVEnd === currVStart;
  };

  const adjustEndDate = (lineEnd, vacations, holidays) => {
    let adjustedEnd = moment(lineEnd);
    let endDay = adjustedEnd.day();

    if (endDay === 5) adjustedEnd = adjustedEnd.add(2, 'days'); // Perjantai -> Maanantai
    if (endDay === 6) adjustedEnd = adjustedEnd.add(1, 'days'); // Lauantai -> Maanantai
    /* 
      let isHolidayOrVacation = vacations.some(vacation =>
        moment(vacation.AlkuPvm).isSameOrBefore(adjustedEnd) &&
        moment(vacation.LoppuPvm).isSameOrAfter(adjustedEnd)
      ) || holidays.some(holiday => moment(holiday.ArkipyhaPvm).isSame(adjustedEnd));
    
      while (isHolidayOrVacation) {
        adjustedEnd = adjustedEnd.add(1, 'days');
        endDay = adjustedEnd.day();
        if (endDay === 5) adjustedEnd = adjustedEnd.add(2, 'days'); // Perjantai -> Maanantai
        if (endDay === 6) adjustedEnd = adjustedEnd.add(1, 'days'); // Lauantai -> Maanantai
    
        isHolidayOrVacation = vacations.some(vacation =>
          moment(vacation.AlkuPvm).isSameOrBefore(adjustedEnd) &&
          moment(vacation.LoppuPvm).isSameOrAfter(adjustedEnd)
        ) || holidays.some(holiday => moment(holiday.ArkipyhaPvm).isSame(adjustedEnd));
      }
     */
    return adjustedEnd;
  };




  const processWorkItems = (row, vacations, holidays, otherdays, newItems, lineStart, newUserWorkHistory) => {
    let lineEnd = moment(lineStart).add(row.AikajananPituus, 'days');
    let lineLength = row.AikajananPituus;
    let lastCustomRowEnd = null;

    //Käydään läpi lomat, mikäli loma osuu työn päälle, piirretään työ siihen asti (Poikkeuksena, mikäli työn ja loman aloituspäivä sama, jolloin työn viivaa ei edes piirretä vielä)
    vacations.forEach(vacation => {
      if (vacation.TyontekijaNro === row.TyontekijaNro) {
        let sameStartDay = getParsedDate(vacation.AlkuPvm) === getParsedDate(lineStart);
        let isRow = isConsecutiveVacation(lastCustomRowEnd, vacation.AlkuPvm)
        if (moment(vacation.AlkuPvm).isBetween(lineStart, lineEnd) || sameStartDay) {
          if (!sameStartDay && !isRow) {
            drawLine(newItems, row.TyontekijaNro, `${row.Tyokohde} | ${row.ElementtiRyhma}`, lineStart, moment(vacation.AlkuPvm).subtract(1, 'days'), 'work', getRowData(row));
            let currLineLength = moment(vacation.AlkuPvm).diff(moment(lineStart), "days"); //Vähennetään loppukuormituksen pituudesta jo piirretty pituus
            lineLength = lineLength - currLineLength;
          }
          lineStart = moment(vacation.LoppuPvm).add(1, 'days');
          lineEnd = moment(vacation.LoppuPvm).add(lineLength, 'days');
        }
      }
    });

    //Käydään läpi juhlapäivät, mikäli päicö osuu työn päälle, piirretään työ siihen asti (Poikkeuksena, mikäli työn ja loman aloituspäivä sama, jolloin työn viivaa ei edes piirretä vielä)
    holidays.forEach(holiday => {
      let sameStartDay = getParsedDate(holiday.ArkipyhaPvm) === getParsedDate(lineStart);
      let isRow = isConsecutiveVacation(lastCustomRowEnd, holiday.ArkipyhaPvm)
      if (moment(holiday.ArkipyhaPvm).isBetween(lineStart, lineEnd) || sameStartDay) {
        if (!sameStartDay && !isRow) {
          drawLine(newItems, row.TyontekijaNro, `${row.Tyokohde} | ${row.ElementtiRyhma}`, lineStart, moment(holiday.ArkipyhaPvm).subtract(1, 'days'), 'work', getRowData(row));
          let currLineLength = moment(holiday.ArkipyhaPvm).diff(moment(lineStart), "days"); //Vähennetään loppukuormituksen pituudesta jo piirretty pituus
          lineLength = lineLength - currLineLength;
        }
        lineStart = moment(holiday.ArkipyhaPvm).add(1, 'days');
        lineEnd = moment(holiday.ArkipyhaPvm).add(lineLength, "days");
      }
    });


    //Käydään läpi juhlapäivät, mikäli päicö osuu työn päälle, piirretään työ siihen asti (Poikkeuksena, mikäli työn ja loman aloituspäivä sama, jolloin työn viivaa ei edes piirretä vielä)
    otherdays.forEach(muu => {
      if (muu.Tyontekija === row.TyontekijaNro) {

        let sameStartDay = getParsedDate(muu.AlkuPvm) === getParsedDate(lineStart);
        let isRow = isConsecutiveVacation(lastCustomRowEnd, muu.AlkuPvm)
        if (moment(muu.AlkuPvm).isBetween(lineStart, lineEnd) || sameStartDay) {
          if (!sameStartDay && !isRow) {
            drawLine(newItems, row.TyontekijaNro, `${row.Tyokohde} | ${row.ElementtiRyhma}`, lineStart, moment(muu.AlkuPvm).subtract(1, 'days'), 'work', getRowData(row));
            let currLineLength = moment(muu.AlkuPvm).diff(moment(lineStart), "days"); //Vähennetään loppukuormituksen pituudesta jo piirretty pituus
            lineLength = lineLength - currLineLength;
          }
          lineStart = moment(muu.LoppuPvm).add(1, 'days');
          lineEnd = moment(muu.LoppuPvm).add(lineLength, 'days');
        }
      }
    });

    //Tarkistetaan pe ja la ja siirretään sunnuntaille jos näin on
    lineEnd = adjustEndDate(lineEnd, vacations, holidays);

    //Piirretään koko/loppuviiva
    drawLine(newItems, row.TyontekijaNro, `${row.Tyokohde} | ${row.ElementtiRyhma}`, lineStart, lineEnd, 'work', getRowData(row));

    //Päivitetään arvioitu valmistuminen kaikkiin riveihin tooltippiä varten
    let shortEnd = getParsedDateShort(lineEnd);
    newItems.map((itemrow, index) => {
      if (itemrow.rowId == row.KirjausId) {
        itemrow.data.pvmReady = shortEnd;
      }
    });

    //Tallennetaan työhistoria tauluun. Käytetään modalistta "Edelliset työt"
    let userId = "user_" + row.TyontekijaNro;
    if (!newUserWorkHistory[userId]) newUserWorkHistory[userId] = [];
    newUserWorkHistory[userId].push({
      KirjausId: row.KirjausId,
      workid: row.Tyokohdenro,
      name: row.Tyokohde,
      elementgroup: row.ElementtiRyhma,
      endDate: lineEnd,
    });
  };



  const processInstallItems = (installRows, vacations, holidays, otherdays, newItems) => {
    installRows.forEach(row => {
      let haveProject = false

      if (row.Tyokohde) haveProject = true

      let lineStart = row.Alku
      let lineEnd = row.Loppu
      let lastCustomRowEnd = null;


      //Käydään läpi lomat, mikäli loma osuu työn päälle, piirretään työ siihen asti (Poikkeuksena, mikäli työn ja loman aloituspäivä sama, jolloin työn viivaa ei edes piirretä vielä)
/*       vacations.forEach(vacation => {
        if (vacation.TyontekijaNro === row.TyontekijaNro) {
          let sameStartDay = getParsedDate(vacation.AlkuPvm) === getParsedDate(lineStart);
          let isRow = isConsecutiveVacation(lastCustomRowEnd, vacation.AlkuPvm)
          if (moment(vacation.AlkuPvm).isBetween(lineStart, lineEnd) || sameStartDay) {
            if (!sameStartDay && !isRow) {
              drawLine(newItems, row.Tyontekija, haveProject ? row.TyokohdeNimi + ' | ' + row.Sisalto : row.Sisalto, lineStart, moment(vacation.AlkuPvm).subtract(1, 'days'), haveProject ? "install_proj" : 'install', {data: {name: (haveProject ? row.TyokohdeNimi : row.Sisalto),text: (haveProject ? row.Sisalto : ''),date: getParsedDate(row.Alku) + ' - ' + getParsedDate(row.Loppu)}});
              let currLineLength = moment(vacation.AlkuPvm).diff(moment(lineStart), "days"); //Vähennetään loppukuormituksen pituudesta jo piirretty pituus
              lineLength = lineLength - currLineLength;
            }
            lineStart = moment(vacation.LoppuPvm).add(1, 'days');
            lineEnd = moment(vacation.LoppuPvm).add(lineLength, 'days');
          }
        }
      }); */

      //Käydään läpi juhlapäivät, mikäli päicö osuu työn päälle, piirretään työ siihen asti (Poikkeuksena, mikäli työn ja loman aloituspäivä sama, jolloin työn viivaa ei edes piirretä vielä)
      holidays.forEach(holiday => {
        let sameStartDay = getParsedDate(holiday.ArkipyhaPvm) === getParsedDate(lineStart);
        let isRow = isConsecutiveVacation(lastCustomRowEnd, holiday.ArkipyhaPvm)
        if (moment(holiday.ArkipyhaPvm).isBetween(lineStart, lineEnd) || sameStartDay) {
          if (!sameStartDay && !isRow) {
            drawLine(newItems, row.Tyontekija, haveProject ? row.TyokohdeNimi + ' | ' + row.Sisalto : row.Sisalto, lineStart, moment(holiday.ArkipyhaPvm).subtract(1, 'days'), haveProject ? "install_proj" : 'install', {data: {name: (haveProject ? row.TyokohdeNimi : row.Sisalto),text: (haveProject ? row.Sisalto : ''),date: getParsedDate(row.Alku) + ' - ' + getParsedDate(row.Loppu)}});
          }
          lineStart = moment(holiday.ArkipyhaPvm).add(1, 'days');
        }
      });


      //Käydään läpi juhlapäivät, mikäli päicö osuu työn päälle, piirretään työ siihen asti (Poikkeuksena, mikäli työn ja loman aloituspäivä sama, jolloin työn viivaa ei edes piirretä vielä)
      otherdays.forEach(muu => {
        if (muu.Tyontekija === row.Tyontekija) {
          let sameStartDay = getParsedDate(muu.AlkuPvm) === getParsedDate(lineStart);
          let isRow = isConsecutiveVacation(lastCustomRowEnd, muu.AlkuPvm)
          if (moment(muu.AlkuPvm).isBetween(lineStart, lineEnd) || sameStartDay) {
            if (!sameStartDay && !isRow) {
              drawLine(newItems, row.Tyontekija, haveProject ? row.TyokohdeNimi + ' | ' + row.Sisalto : row.Sisalto, lineStart, moment(muu.AlkuPvm).subtract(1, 'days'), haveProject ? "install_proj" : 'install', {data: {name: (haveProject ? row.TyokohdeNimi : row.Sisalto),text: (haveProject ? row.Sisalto : ''),date: getParsedDate(row.Alku) + ' - ' + getParsedDate(row.Loppu)}});
            }
            lineStart = moment(muu.LoppuPvm).add(1, 'days');
          }
        }
      });

      //Tarkistetaan pe ja la ja siirretään sunnuntaille jos näin on
      lineEnd = adjustEndDate(lineEnd, vacations, holidays);

      //Piirretään loppuviiva
      drawLine(newItems, row.Tyontekija, haveProject ? row.TyokohdeNimi + ' | ' + row.Sisalto : row.Sisalto, lineStart, lineEnd, haveProject ? "install_proj" : 'install', {data: {name: (haveProject ? row.TyokohdeNimi : row.Sisalto),text: (haveProject ? row.Sisalto : ''),date: getParsedDate(row.Alku) + ' - ' + getParsedDate(row.Loppu)}});
    })
  };



  // Käy läpi kaikki lomat ja pyhäpäivät ja lisää ne `newItems`-taulukkoon
  const addSpecialDays = (lomat1, pyhat1, muut, newItems, newGroups) => {
    lomat1.forEach(vacation => {
      drawLine(newItems, vacation.TyontekijaNro, vacation.PoissaoloSyy, vacation.AlkuPvm, vacation.LoppuPvm, 'vacation', {
        vacationId: vacation.PoissaoloNro,
        data: {
          name: vacation.PoissaoloSyy,
          startdate: getParsedDateShort(vacation.AlkuPvm),
          enddate: getParsedDate(vacation.LoppuPvm),
          durationD: vacation.KestoPv,
          durationH: vacation.KestoH,
        },
      });
    });

    pyhat1.forEach(holiday => {
      newGroups.forEach(group => {
        if (!group.subtitle) {
          drawLine(newItems, group.id, holiday.Selite, holiday.ArkipyhaPvm, holiday.ArkipyhaPvm, 'holiday', {
            holidayId: holiday.ArkipyhaId,
            data: {
              name: holiday.Selite,
              date: getParsedDate(holiday.ArkipyhaPvm),
            },
          });
        }
      });
    });

    muut.forEach(muu => {
      drawLine(newItems, muu.Tyontekija, muu.Sisalto, muu.AlkuPvm, muu.LoppuPvm, 'otherdate', {
        otherdateId: muu.MerkintaId,
        data: {
          name: muu.Sisalto,
          startdate: getParsedDateShort(muu.AlkuPvm),
          enddate: getParsedDate(muu.LoppuPvm)
        },
      });
    });
  };





  //Rakennetaan data aikataulunäkymää varten
  useEffect(() => {
    if (usersData?.tyontekijasisalto || usersData?.lomat) {
      let newUserWorkHistory = [];
      let newGroups = [];
      let newItems = [];
      let lastLineEnd = {}; // Tallentaa viimeisimmän työn tiedot

      let jobEnded = [];


      //Lajitellaan pyhät päivämäärän mukaan jotta rivit muodostuu oikein
      usersData?.arkipyhat?.sort(function (a, b) {
        return new Date(a.ArkipyhaPvm) - new Date(b.ArkipyhaPvm);
      });



      // TUOTANTO
      newGroups.push({
        id: "workers",
        title: "Tuotanto",
        isOpenableProject: true,
        subtitle: true,
      });

      usersData?.tyontekijasisalto?.map((row, index) => {
        //Create group if not exists
        if (!newGroups.some((person) => person.id === row.TyontekijaNro)) {
          if (groupsOpen["workers"]) {
            newGroups.push({ id: row.TyontekijaNro, title: row.TyontekijaNimi });
          }
        }

        if (row.AikajananPituus) {
          // Hae edellisen työn päättymispäivä, jos sama työntekijä
          let currDate = lastLineEnd[row.TyontekijaNro] ? moment(lastLineEnd[row.TyontekijaNro].date).add(1, "days") : moment();
          processWorkItems(row, usersData.lomat, usersData.arkipyhat, usersData.muutrivit, newItems, currDate, newUserWorkHistory);

          // Päivitä viimeisin työn päättymispäivä tälle työntekijälle
          lastLineEnd[row.TyontekijaNro] = {
            date: newItems[newItems.length - 1].end_time,
            user: row.TyontekijaNro
          };
        }
      });


      //Piirretään työsuhdepäättänyt viiva mikäli on
      usersData?.tyontekijasisalto?.map((row, index) => {
        if (row.ResurssinPaattymisPvm && !jobEnded[row.TyontekijaNro]) {
          jobEnded[row.TyontekijaNro] = true
          drawLine(newItems, row.TyontekijaNro, "Työsuhde päättynyt", moment(row.ResurssinPaattymisPvm).add(1, "days"), moment(row.ResurssinPaattymisPvm).add(1, "year"), "jobended");
        }
      });



      //ASENNUS
      let groupInstall = "other_Asennus";

      if (usersData?.asennustekijat?.length >= 1) {
        newGroups.push({
          id: groupInstall,
          title: 'Asennus',
          isOpenableProject: true,
          subtitle: true,
        });
      }

      usersData?.asennustekijat?.map((worker, index) => {
        console.log("asennustekijat", worker);

        //Create group if not exists
        if (!newGroups.some((person) => person.id === worker.TyontekijaNro)) {
          if (groupsOpen[groupInstall]) {
            newGroups.push({ id: worker.TyontekijaNro, title: worker.TyontekijaNimi, installworker: true });
          }
        }
      });

      // usersData?.asennusrivit
/*       let test3 = [
        {
          AsennusId: 1,
          Tyontekija: 338,
          Tyokohde: 1,
          Alku: moment('2024-08-01'),
          Loppu: moment('2024-08-20'),
          Sisalto: 'Test',
          TyontekijaNimi: 'TEST',
          TyokohdeNimi: 'TEST23'

        }
      ] */


      processInstallItems(usersData?.asennusrivit, usersData.lomat, usersData.arkipyhat, usersData.muutrivit, newItems)

      //OTHERS
      //Luodaan loput työntekijät/groupit
      usersData?.muuttyontekijat?.map((group, index) => {
        if (group.RyhmaNimi) {
          let groupId = "other_" + group.RyhmaNimi;

          //Create group if not exists
          if (!newGroups.some((project) => project.id === groupId)) {
            newGroups.push({
              id: groupId,
              title: group.RyhmaNimi,
              isOpenableProject: true,
              subtitle: true,
            });
          }

          if (groupsOpen[groupId]) {
            newGroups.push({
              id: group.TyontekijaNro,
              title: group.TyontekijaNimi,
              stockworker: true,
            });
          }
        }
      });


      // Lisätään kaikki lomat ja pyhäpäivät kakille groupeille
      addSpecialDays(usersData.lomat, usersData.arkipyhat, usersData.muutrivit, newItems, newGroups);


      console.log("newUserWorkHistory", newUserWorkHistory);
      console.log("newGroups", newGroups);
      console.log("newItems", newItems);

      setUserWorks(newUserWorkHistory);
      setGroupsData(newGroups);
      setItemsData(newItems);
    }
  }, [usersData, groupsOpen]);

  const groupRenderer = ({ group }) => {
    return (
      <>
        {group.subtitle ? (
          <div
            className={`subtitle-group`}
            onClick={() => {
              console.log('click', group)

              if (group.isOpenableProject) {
                let OpenList = groupsOpen;
                let IsOpen = OpenList[group.id];
                OpenList[group.id] = !IsOpen;

                //setGroupsData([]);

                //Odotetaan 200ms ennenkuin lasketaan/näytetään uudet viivat -> ei näyttö välähtele niin paljon
                //setTimeout(() => {
                setGroupsOpen({
                  ...OpenList,
                });
                //}, 200);
              }
            }}
          >
            <span className={`title font-itc`}>{group.title}</span>
          </div>
        ) : (
          <div className={`custom-group`}>
            <span className={`title font-dm-bold`}>{group.title}</span>

            {!group.no_add && (
              <PlusCircleOutlined
                className="add"
                onClick={() => {
                  let userworksArray = userWorks["user_" + group.id] || [];

                  let jobEnding = usersData.tyontekijasisalto.find(
                    (row) =>
                      row.TyontekijaNro === group.id &&
                      row.ResurssinPaattymisPvm != null
                  )?.ResurssinPaattymisPvm;

                  // let workData = usersData.tyontekijasisalto.find(
                  //   (row) => row.KirjausId === item.rowId
                  // );

                  console.log("testi:_", usersData.tyontekijasisalto)

                  ////console.log('jobEnding', jobEnding)

                  // if (group.stockworker)



                  setModalData({
                    userId: group.id,
                    userName: group.title,
                    userWorks: userworksArray,
                    jobEnding: jobEnding,
                    stockWorker: group.stockworker && true,
                    installWorker: group.installworker && true,
                  });
                  setModalOpen(true);
                }}
              />
            )}
          </div>
        )}
      </>
    );
  };


  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    let itemProps = getItemProps({});

    let tooltipTypes = {
      work: true,
      vacation: true,
      holiday: true,
      install: true,
      install_proj: true,
      otherdate:true
    };

    let showTooltip = tooltipTypes[item.type];


    if (item.type == "jobended") {
      //console.log("stage getItemProps:", itemProps.style);
      var top = parseFloat(itemProps.style.top.slice(0, -2));
      var height = parseFloat(itemProps.style.height.slice(0, -2));

      height = 20;
      top = top + 5;

      itemProps.style.top = top + "px";
      itemProps.style.height = height + "px";
      itemProps.style.lineHeight = height + "px";
    }

    return (
      <>
        <div
          data-tip
          data-for={"tooltip-" + item.id}
          {...itemProps}
          onMouseDown={(e) => {
            if (e.detail == 1) {
              console.log("on item click", item);

            }

            if (e.detail == 2) {
              console.log("on item dobleclick", item);


              if (item.type == "vacation") {
                let vacationData = usersData.lomat.find(
                  (row) => row.PoissaoloNro === item.vacationId
                );

                let kayttaja = []

                kayttaja = usersData.muuttyontekijat.find(
                  (row) => row.TyontekijaNro === vacationData.TyontekijaNro
                )

                if (!kayttaja) {
                  kayttaja = usersData.tyontekijasisalto.find(
                    (row) => row.TyontekijaNro === vacationData.TyontekijaNro
                  )
                }

                console.log("vacation", vacationData)

                setModalOpen(true)
                setModalData({
                  alku: vacationData.AlkuPvm,
                  kestoH: vacationData.KestoH,
                  kestoPv: vacationData.kestoPv,
                  loppu: vacationData.LoppuPvm,
                  pekkanen: vacationData.Pekkanen,
                  poissaoloNro: vacationData.PoissaoloNro,
                  poissaoloSyy: vacationData.PoissaoloSyy,
                  poissaoloSyyNro: vacationData.PoissaoloSyyNro,
                  sairasloma: vacationData.Sairasloma,
                  tehdas: vacationData.Tehdas,
                  todellinenAlku: vacationData.TodellinenAlkuPvm,
                  tyoaikapankki: vacationData.TyoaikapankkiVapaa,
                  tyontekijaNro: vacationData.TyontekijaNro,
                  vuosiloma: vacationData.Vuosiloma,
                  username: kayttaja.TyontekijaNimi,
                  userId: kayttaja.TyontekijaNro
                })

                console.log("vacation", vacationData)
              }
              
              
              
              if (item.type == "work") {

                let workData = usersData.tyontekijasisalto.find(
                  (row) => row.KirjausId === item.rowId
                );

                let SelectedWork = usersData.tulevatuotanto.find(
                  (row) => row.ElementtiRyhmaNro === workData.ElementtiRyhmaNro
                );

                console.log("on workData dobleclick", workData);
                console.log("on SelectedWork dobleclick", SelectedWork);

                //Haetaan muut henkilön työt
                let userworksArray = (
                  userWorks["user_" + workData.TyontekijaNro] || []
                ).filter((workarr) => workarr.KirjausId != item.rowId);

                let jobEnding = usersData.tyontekijasisalto.find(
                  (row) => row.TyontekijaNro === workData.TyontekijaNro
                )?.ResurssinPaattymisPvm;

                setModalData({
                  editRow: true,
                  rowId: workData.KirjausId,
                  userId: workData.TyontekijaNro,
                  userName: workData.TyontekijaNimi,
                  userWorks: userworksArray,
                  jobEnding: jobEnding,
                  lastWork: workData.EdellinenTyo,
                  Tyokohdenro: workData.Tyokohdenro,
                  ElementtiRyhma: workData.ElementtiRyhma,
                  ElementtiRyhmaNro: workData.ElementtiRyhmaNro,
                  KuormittamattaKpl: workData.KuormittamattaKpl,
                  ValmistamattaKpl: workData.ValmistamattaKpl,
                  workTitle: workData.Tyokohde,
                  multiplier: workData.HenkilokohtainenKerroin,
                  percent: workData.KuormitusOsuus,
                  table: workData.PoytaNro,
                  reward: workData.ArvioTuotantopalkkioYht,
                  SelectedWork: SelectedWork,
                });

                setModalOpen(true);
              }


              //otherdateId

              if (item.type == "otherdate") {


                let otherdateData =  usersData.muutrivit.find(
                  (row) => row.MerkintaId === item.otherdateId
                );

                console.log('otherdateId', otherdateData)
/*                 setModalData({
                  editRow: true,
                  rowId: workData.KirjausId,
                  userId: workData.TyontekijaNro,
                  userName: workData.TyontekijaNimi,
                  userWorks: userworksArray,
                  jobEnding: jobEnding,
                  lastWork: workData.EdellinenTyo,
                  Tyokohdenro: workData.Tyokohdenro,
                  ElementtiRyhma: workData.ElementtiRyhma,
                  ElementtiRyhmaNro: workData.ElementtiRyhmaNro,
                  KuormittamattaKpl: workData.KuormittamattaKpl,
                  ValmistamattaKpl: workData.ValmistamattaKpl,
                  workTitle: workData.Tyokohde,
                  multiplier: workData.HenkilokohtainenKerroin,
                  percent: workData.KuormitusOsuus,
                  table: workData.PoytaNro,
                  reward: workData.ArvioTuotantopalkkioYht,
                  SelectedWork: SelectedWork,
                }); */

                setModalData({
                  otherdateId: item.otherdateId,
                  AlkuPvm: otherdateData.AlkuPvm,
                  LoppuPvm: otherdateData.LoppuPvm,
                  MerkintaId: otherdateData.MerkintaId,
                  Sisalto: otherdateData.Sisalto,
                  Tyontekija: otherdateData.Tyontekija,
                  userName: 'testinimi'
                });

                setModalOpen(true);
              }

            }
          }}
        >
          <div
            style={{
              height: itemContext.dimensions.height,
              paddingLeft: 5,
              paddingRight: 3,
              borderRadius: 10,
            }}
          >
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              className="font-dm-regular"
            >
              {itemContext.title}
            </p>
          </div>
        </div>

        {showTooltip && (
          <DrawTooltip
            id={"tooltip-" + item.id}
            type={item.type}
            data={item.data}
          />
        )}
      </>
    );
  };

  /*   if (isLoading) {
    return <Loading />;
  } */

  return (
    <>
      <AddWorkModal
        tables={usersData.poydat}
        elements={usersData.tulevatuotanto}
        installWorks={usersData.asennuskohteet}
        data={modalData}
        isOpen={modalOpen}
        closeModal={(refresh) => {
          setModalOpen(false);
          //console.log("refresh: ", refresh);
          if (refresh) getUsersData();
        }}
      />
      <div className="tl-container">
        {groupsData && groupsData.length > 0 && (
          <div id={`capture-henkilosto`}>
            <Timeline /* resizeDetector={containerResizeDetector} */
              groups={groupsData}
              items={itemsData}
              defaultTimeStart={moment().hours(0)}
              defaultTimeEnd={moment().hours(0).add(4, "week")}
              /*               visibleTimeStart={moment().hours(0)}
              visibleTimeEnd={moment().hours(0).add(1, "year")} */
              canMove={false}
              canResize={false}
              canChangeGroup={false}
             /*  stackItems */

              timeSteps={{
                second: 1,
                minute: 1,
                hour: 1,
                day: 1,
                month: 1,
                year: 1,
              }}
              sidebarWidth={220}
              lineHeight={50}
              itemRenderer={itemRenderer}
              groupRenderer={groupRenderer}
              /*               horizontalLineClassNamesForGroup={(group) => ["custom-group"]}
               */ minZoom={MIN_ZOOM}
              maxZoom={MAX_ZOOM}
              onItemSelect={() => {
                //console.log("item selected");
              }}
              onItemClick={null}
            >
              <TimelineHeaders>
                <SidebarHeader>
                  {({ getRootProps }) => {
                    return (
                      <div {...getRootProps()}>
                        {/* 
                        <p className="timeline-left-header font-itc">
                          Henkilöstö
                        </p> */}

                        {!btnAllOpen ? (
                          <UpOutlined
                            className="timeline-left-arrow"
                            onClick={() => {
                              handleBtnOpenAll();
                            }}
                          />
                        ) : (
                          <DownOutlined
                            className="timeline-left-arrow"
                            onClick={() => {
                              handleBtnOpenAll();
                            }}
                          />
                        )}

                        {/*                {item.open ? (
                        <UpOutlined className="arrow" />
                      ) : (
                        <DownOutlined className="arrow" />
                      )} */}
                      </div>
                    );
                  }}
                </SidebarHeader>
                <DateHeader unit="week" />
                <DateHeader />
              </TimelineHeaders>
            </Timeline>
          </div>
        )}
      </div>
      ,
    </>
  );
};
