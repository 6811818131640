import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiSecure } from "../../components/api";
import { Loading, LoadingItem } from "../../components/Loading/Loading";
import "./Project.css";

import { useTranslate } from "../../components/Translate";


import { useMediaQuery } from "react-responsive";
import { getEnvSettings } from "../../config/config";

import { EnvironmentOutlined, EditOutlined, FileOutlined, PlusOutlined, FileFilled } from '@ant-design/icons';

import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { EditContactModal } from "./EditContactModal";
import { EditAddressModal } from "./EditAddressModal";
import { EditScheduleModal } from "./EditScheduleModal";
import { EditReceiverModal } from "./EditReceiverModal";
import { AddEditNoteModal } from "./AddEditNoteModal";
import { getParsedDate } from "../../components/globalFunctions";



export const ProjectNotes = ({ navigate, projectData, getProject }) => {
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isMediumWidth = useMediaQuery({ maxWidth: 1100 });


  const [isLoading, setisLoading] = useState(false);

  const [notes, setNotes] = useState();

  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
  const [editNoteData, setEditNoteData] = useState({});



  const getNotes = () => {
    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getProjectNotes`,
      data: {
        projectId: projectData?.tiedot?.Tyokohdenro,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        setNotes(response.data.data)
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  useEffect(() => {
    console.log('getNotes')
    getNotes()
  }, []);

  const addOrUpdateNote = (noteId, date, name, text, who, others) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}${noteId ? 'updateProjectNote' : 'newProjectNote'}`,
      data: {
        projectId: projectData?.tiedot?.Tyokohdenro,
        noteId: noteId,
        date: date,
        name: name,
        text: text,
        who: who, 
        others: others
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        getNotes()
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  

  let datas = [
    { id: 1, title: 'Suunnittelu Palaveri 1.', date: "1.8.2024" },
    { id: 2, title: 'Suunnittelu Palaveri 2.', date: "1.8.2024" },
    { id: 3, title: 'Suunnittelu Palaveri 3.', date: "1.8.2024" },
    { id: 3, title: 'Suunnittelu Palaveri 3.', date: "1.8.2024" },
    { id: 3, title: 'Suunnittelu Palaveri 3.', date: "1.8.2024" },
    { id: 3, title: 'Suunnittelu Palaveri 3.', date: "1.8.2024" },
    { id: 3, title: 'Suunnittelu Palaveri 3.', date: "1.8.2024" },
    { id: 3, title: 'Suunnittelu Palaveri 3.', date: "1.8.2024" },
    { id: 3, title: 'Suunnittelu Palaveri 3.', date: "1.8.2024" },
    { id: 3, title: 'Suunnittelu Palaveri 3.', date: "1.8.2024" },
    { id: 3, title: 'Suunnittelu Palaveri 3.', date: "1.8.2024" },
    { id: 3, title: 'Suunnittelu Palaveri 3.', date: "1.8.2024" },
    { id: 3, title: 'Suunnittelu Palaveri 3.', date: "1.8.2024" },
    { id: 3, title: 'Suunnittelu Palaveri 3.', date: "1.8.2024" },
  ]



  return (<div className={`projectpage-content`}>

    <AddEditNoteModal
      isOpen={isAddNoteModalOpen}
      closeModal={() => {
        setIsAddNoteModalOpen(false);
      }}
      data={editNoteData}
      setData={setEditNoteData}
      saveFunc={addOrUpdateNote}
    />

    <div className="project-info">
      <p className="project-info-title font-dm-bold">Muistio</p>

      <div className="project-notes">
      {!isLoading ? <> 
        {notes?.map((itm) => {
          return <div className="project-note clickable" key={itm.NeuvotteluNro} onClick={()=>{
            setEditNoteData(itm)
            setIsAddNoteModalOpen(true)
          }}>
            <FileFilled className="project-note-icon" />
            <div className="project-note-title font-dm-bold">{itm.Kuvaus}</div>
            <div className="project-note-date font-dm-medium">{getParsedDate(itm.pvm)}</div>
          </div>
        })}

        <div className="project-note-addCard" onClick={()=>{
          setIsAddNoteModalOpen(true)
        }}>
          <PlusOutlined className="project-note-addIcon" />
        </div>
        </> : <LoadingItem color={"black"} />}
      </div>

    </div>

  </div>

  );
};
