import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Modal from "react-modal";

import { DatePicker, Input } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../components/Translate";

const { TextArea } = Input;

const ModalHeader = ({ closeModal, header, user }) => {
  return (
    <div>
      <div className="proj-modalHeaderRow">
        <p className="proj-modalHeader font-dm-bold">{header}</p>
        <CloseOutlined
          className="proj-modalHeaderClose"
          onClick={() => closeModal()}
        />
      </div>
      <p className="proj-modalHeaderUser font-dm-medium">{user}</p>
    </div>
  );
};

const SaveBtn = ({ onClick }) => {
  const { locale } = useTranslate();

  return (
    <div className="proj-saveButtonCont">
      <button className="proj-saveButton font-dm-regular" onClick={onClick}>
        {locale.save}
      </button>
    </div>
  );
};

export const AddEditNoteModal = ({ isOpen, closeModal, data, setData, saveFunc }) => {
  console.log("AddEditNoteModal data", data);
  const [date, setDate] = useState(null);

  const [name, setName] = useState(null);
  const [text, setText] = useState(null);
  const [who, setWho] = useState(null);
  const [others, setOthers] = useState(null);

  const { locale } = useTranslate();

  useEffect(() => {
    console.log("isOpen updated", isOpen, data);
    if (isOpen) {

      if (data?.NeuvotteluNro) {
        setDate(data?.pvm)
        setName(data?.Kuvaus)
        setText(data?.Teksti)
        setWho(data?.Kuka)
        setOthers(data?.KenenKans)
      }

    } else {
      setData({})
      setDate(new Date())
      setName(null)
      setText(null)
      setWho(null)
      setOthers(null)
    }

  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        className="workmodalBase"
        style={{
          overlay: {
            zIndex: 100,
            backgroundColor: "rgba(70, 70, 70, 0.5)",
          },
        }}
        ariaHideApp={false}
      >
        <div className="proj-modalContainer">
          <div className="proj-modal" style={{ minWidth: '400px' }}>
            <ModalHeader
              closeModal={() => closeModal()}
              header={data?.NeuvotteluNro ? "Muokkaa muistiota" : "Lisää muistio"}
            />

            <p className="font-dm-medium label">{"Päivämäärä"}</p>
            <DatePicker
              placeholder={""}
              format={'DD.MM.YYYY'}
              size="small"
              allowClear={false}
              className={`NoteDatepicker font-dm-regular`}
              value={date ? dayjs(date) : null}
              onChange={(
                date,
                dateString
              ) => {
                let saveDate = dayjs(date).format("YYYY-MM-DD") //Tietokantaa varten tähän tmalliin     
                setDate(saveDate)
              }}
            />

            <p className="font-dm-medium label">{"Nimi"}</p>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                console.log("onChange", e.target.value)
              }}
            />

            <p className="font-dm-medium label">{"Sisältö"}</p>
            <TextArea
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Lisää sisältöä"
              autoSize={{ minRows: 3, maxRows: 3 }}
            />

            <p className="font-dm-medium label">{"Kuka"}</p>
            <Input
              value={who}
              onChange={(e) => {
                setWho(e.target.value)
                console.log("onChange", e.target.value)
              }}
            />

            <p className="font-dm-medium label">{"Kenen kanssa"}</p>
            <Input
              value={others}
              onChange={(e) => {
                setOthers(e.target.value)
                console.log("onChange", e.target.value)
              }}
            />



            <SaveBtn onClick={() => {
              closeModal()
              saveFunc(data?.NeuvotteluNro, date, name, text, who, others)
            }
            } />
          </div>
        </div>
      </Modal>
    </>
  );
};
