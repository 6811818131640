export const formatdata = (data) => {
  if (data) {
    return data;
  } else {
    return null;
  }
};


export const getParsedDateShort = (strDate) => {
  if (strDate) {
    var date = new Date(strDate);

    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!

    date = dd + "." + mm + ".";

    return date.toString();
  } else {
    return null;
  }
};

export const getParsedDate = (strDate) => {
  if (strDate) {
    var date = new Date(strDate);

    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!
    var year = date.getFullYear();

    date = dd + "." + mm + "." + year;

    return date.toString();
  } else {
    return null;
  }
};

export const getParsedTime = (strDate) => {
  if (strDate) {
    var date = new Date(strDate);

    //console.log('getParsedTime', date)

    //IosFix
    var offset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() + offset);

    var hours = date.getHours();
    var minutes = date.getMinutes();

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    date = hours + ":" + minutes;

    return date.toString();
  } else {
    return null;
  }
};

export const getParsedTimeNoZero = (strDate) => {
  if (strDate) {
    var date = new Date(strDate);

    //console.log('getParsedTime', date)

    //IosFix
    var offset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() + offset);

    var hours = date.getHours();
    var minutes = date.getMinutes();

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (hours == '00' && minutes == '00') {
      date = ""
    } else {
      date = hours + ":" + minutes;
    }

    

    return date.toString();
  } else {
    return null;
  }
};