import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiSecure } from "../../components/api";
import { Loading, LoadingItem } from "../../components/Loading/Loading";
import "./Project.css";

import {
  FilePdfOutlined,
  FileOutlined,
  InfoCircleFilled,
  MergeCellsOutlined,
  PlusOutlined,
  LeftOutlined,
  EditOutlined,
  HomeFilled
} from "@ant-design/icons";


import { DatePicker } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getParsedDate } from "../../components/globalFunctions";

import { useTranslate } from "../../components/Translate";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import moment, { isMoment } from "moment";


import { NewStage } from "./NewStage";
import { copy, move, reorder } from "./ProjectDraggable";
import { EditStageData } from "./EditStageData";
import { VerifyDelete } from "./VerifyDelete";
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";
import { getEnvSettings } from "../../config/config";
import { ProjectElements } from "./ProjectElements";
import { ProjectInfo } from "./ProjectInfo";
import { ProjectNotes } from "./ProjectNotes";



export const Project = ({ setToken }) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const { locale } = useTranslate();
  let { id } = useParams();
  const navigate = useNavigate();
  const envConfig = getEnvSettings();

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isMediumWidth = useMediaQuery({ maxWidth: 1100 });




  const [animation, setAnimation] = useState("");
  const [useDragging, setUseDragging] = useState(false);

  const [enableEditing, setEnableEditing] = useState(false);

  const [copiedList, setCopiedList] = useState({});
  const [droppableLists, setDroppableLists] = useState({});

  const { tehtaat, lang, selectedFactory } = useSelector((state) => state.currentUser.user);
  const [listType, setListType] = useState("elementit");

  const [itemsInDroppable, setItemsInDroppable] = useState({});

  const [stageDataOpen, setStageDataOpen] = useState(false);
  const [stageData, setStageData] = useState({});

  const [newStageOpen, setNewStageOpen] = useState(false);

  const [verifyDeleteOpen, setVerifyDeleteOpen] = useState(false);
  const [verifyDeleteData, setVerifyDeleteData] = useState({});

  const [screen, setScreen] = useState("elements"); // elements, notes, info

    //HUOM
    // Nollaa kaikki tilat aina kun id muuttuu
    useEffect(() => {
      console.log("ID muuttui:", id);
      
      // Nollataan kaikki tilat
      setAnimation("");
      setUseDragging(false);
      setEnableEditing(false);
      setCopiedList({});
      setDroppableLists({});
      setListType("elementit");
      setItemsInDroppable({});
      setStageDataOpen(false);
      setStageData({});
      setNewStageOpen(false);
      setVerifyDeleteOpen(false);
      setVerifyDeleteData({});
      setScreen("elements");
  
    }, [id]); // Tämä suoritetaan aina, kun id muuttuu
  


  //Tehdasmuutos
  /*   useEffect(() => {
      if (isFirstRender.current) {
        // Ensimmäinen renderöinti, ei suoriteta funktiota
        isFirstRender.current = false;
        return;
      }
  
      console.log('selectedFactory change', selectedFactory)
      navigate("/projects/")
    }, [selectedFactory]); // Lisää selectedFactory riippuvuuslistaan
   */



  //////
  //////  DRAGGING START
  //////
  /*   useEffect(() => {
    console.log("COPIEDLIST Update", copiedList);
  }, [copiedList]); */


  useEffect(() => {
    //console.log("CREATE ETAPIT");
    //console.log(projectData.etapit); 

    if (projectData.etapit) {
      //console.log("CREATE ETAPIT2");

      let newData = {};

      projectData.etapit.map((item, index) => {
        let isParent = item.ParentID === 0 ? true : false;

        newData["LIST_" + item.EtappiNro] = {
          title: item.Etappi,
          parentlist: isParent ? item.EtappiNro : item.ParentID,
          items: [],
          parent: isParent,
          startdate: item.ArvioituToimituksenAlku,
          enddate: item.ArvioituToimituksenLoppu,
        };

        /*         if (item.ParentID === 0) {
          newData["LIST_" + item.EtappiNro] = {
            title: item.Etappi,
            parentlist: item.EtappiNro,
            items: [],
            parent: true,
            startdate: item.ArvioituToimituksenAlku,
            enddate: item.ArvioituToimituksenLoppu,
          };
        } else {
          newData["LIST_" + item.EtappiNro] = {
            title: item.Etappi,
            parentlist: item.ParentID,
            items: [],
            startdate: item.ArvioituToimituksenAlku,
            enddate: item.ArvioituToimituksenLoppu,
          };
        } */
      });

      //console.log("creategroups", projectData.elementtiryhmat);

      projectData.elementtiryhmat.map((group, index) => {
        group.data &&
          group.data.map((item, index2) => {
            if (!item.Etappi) return;

            //console.log("FOUND ETAPPI", item);

            if (newData["LIST_" + item.Etappi]) {
              newData["LIST_" + item.Etappi].items.push({
                id: item.ValmistusNro + "_dropped",
                date: item.ArvioituToimitusPvm,
                order: item.JarjestysEtapissa,
              });
            }
          });
      });

      //LAitetaan etapit järjestykseen
      Object.keys(newData).map((list, index) => {
        let items = newData[list].items;

        items.sort(function (a, b) {
          return a.order - b.order;
        });
      });

      //console.log("NEW DATA", newData);

      setDroppableLists(newData);
    }
  }, [projectData]);


  useEffect(() => {
    if (Object.keys(droppableLists).length <= 0) return;

    /*     console.log(
          "DroppableUpdate",
          Object.keys(droppableLists).length,
          droppableLists
        ); */
    let newArray = {};

    Object.keys(droppableLists).map((list, index) => {
      droppableLists[list].items.map((item, index2) => {
        console.log(item);

        let itemId = parseInt(item.id.replace("_dropped", ""));

        newArray[itemId] = true;
      });
    });

/*     console.log("itemsInDroppable", newArray);
 */    setItemsInDroppable(newArray);
  }, [droppableLists]);


  const handleShowDragging = async () => {
    if (useDragging) {
      setAnimation("closing");
      await new Promise((r) => setTimeout(r, 500)); // 0.5s delay
      setAnimation("closed");
    } else {
      setAnimation("open");
    }
    setUseDragging(!useDragging);
  };




  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    //console.log("onDragEnd ==> result", result);

    // dropped outside the list
    if (!destination) {
      return;
    }

    switch (true) {
      case source.droppableId === destination.droppableId: //Sijainnin vaihto listassa
        //console.log("CASE 1");
        setDroppableLists({
          ...droppableLists,
          [destination.droppableId]: {
            ...droppableLists[destination.droppableId],
            items: reorder(
              droppableLists[source.droppableId].items,
              source.index,
              destination.index
            ),
          },
        });
        break;

      case source.droppableId.includes("GROUP"): //Listasta tauluun siirto
        //console.log("CASE 2");

        let isInArray = isDraggableInAnyList(result.draggableId);

        //console.log("isInArray", isInArray);

        if (isInArray) break;

        setDroppableLists({
          ...droppableLists,
          [destination.droppableId]: {
            ...droppableLists[destination.droppableId],
            items: copy(
              null,
              draggableId + "_dropped",
              droppableLists[destination.droppableId].items,
              destination
            ),
          },
        });
        break;

      case source.droppableId.includes("LIST"): //Taulusta toiseen siirto
        //console.log("CASE 3");

        setDroppableLists({
          ...returnRemoveDraggableArray(source.droppableId, draggableId),
          [destination.droppableId]: {
            ...droppableLists[destination.droppableId],
            items: copy(
              source.droppableId,
              draggableId,
              droppableLists[destination.droppableId].items,
              destination
            ),
          },
        });

        break;

      default: //Jos muuta ei ole
        //console.log("CASE 4");

        if (source.droppableId.includes("GROUP")) return;
        return;
        setDroppableLists(
          move(
            droppableLists[source.droppableId],
            droppableLists[destination.droppableId],
            source,
            destination
          )
        );
        break;
    }
  };

  const onBeforeCapture = (result) => {
    console.log("onBeforeCapture");
    //console.log("==> beforeresult", result);


    const id = result.draggableId;

    if (id.includes("dropped")) return;

    const cardSize = 140;
    const draggableAttr = "data-rbd-drag-handle-draggable-id";
    const getAttr = (key, value) => `[${key}="${value}"]`;
    const draggableQuery = getAttr(draggableAttr, id);
    //console.log("draggableQuery:", draggableQuery);
    const draggable = document.querySelector(draggableQuery);
    // console.log("DRAGGABLE:", draggable);

    //console.log('coords22', draggable.getBoundingClientRect())
    draggable.setAttribute("style", `width: ${cardSize}px;`);
  };




  const getRowData = (id) => {
    //console.log('getRowData')

    if (!projectData.elementtiryhmat) return;

    let data = {};

    projectData.elementtiryhmat.map((group, index) => {
      //console.log('group', group)

      if (!group.data) return "asd";

      let foundItem = group.data.filter(
        (element) => element.ValmistusNro === id
      );

      if (foundItem.length >= 1) {
        //console.log('Setting data')
        data = foundItem[0];
      }
      //console.log('foundItem', foundItem)
    });

    //console.log('returning ', data)
    return data;
  };

  const handleOpenEtappiData = (id, name, start, end, parent) => {
    if (enableEditing) return;

    console.log("handleOpenEtappiDate", id);

    setStageData({
      id,
      name,
      start,
      end,
      parent
    });
    setStageDataOpen(true);
  };


  const returnRemoveDraggableArray = (list_id, draggable_id) => {
    //console.log("removeDraggable list", droppableLists);
    let newArray = droppableLists[list_id].items.filter(
      (item) => item.id != draggable_id
    );
    //console.log("removeDraggable newArray", newArray);

    return {
      ...droppableLists,
      [list_id]: {
        ...droppableLists[list_id],
        items: newArray,
      },
    };
  };

  const removeDraggable = (list_id, draggable_id) => {
    setDroppableLists(returnRemoveDraggableArray(list_id, draggable_id));
  };


  const isDraggableInAnyList = (draggable_id) => {
    //console.log("isDraggableInAnyList");

    let inArray = false;

    Object.keys(droppableLists).map((list, index) => {
      let found = droppableLists[list].items.some(
        (x) => x.id === draggable_id + "_dropped"
      );
      if (found) inArray = true;
    });

    return inArray;
  };
  //////
  //////  DRAGGING END
  //////



  /* Other BEGINS */
  const getProject = () => {
    setisLoading(true);
    console.log("getProject");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getProject`,
      data: {
        projectId: id,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        //console.log("RESPONSE", response.data);
        setProjectData(response.data.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getProject()
  }, [id]); // Lisää selectedFactory riippuvuuslistaan

  if (isLoading) {
    return <Loading />;
  }

  if (!projectData.tiedot) {
    return (
      <>
        <p>Ei tietoa</p>
        <button onClick={getProject}>{"HAE PROJEKTI"}</button>
        <button onClick={() => navigate(-1)}>Go back</button>
      </>
    );
  }



  const updateStageDatas = (stage, name, start, end) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateStageDatas`,
      data: {
        projectId: id,
        stage: stage,
        name: name,
        start: start,
        end: end,
      },
    };
    // console.log(req_config);

    apiSecure(req_config)
      .then((response) => {
        //      console.log("RESPONSE", response.data);
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };


  const deleteStage = (stage) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}deleteStage`,
      data: {
        projectId: id,
        stage: stage,
      },
    };

    //console.log("deletestage", req_config);

    apiSecure(req_config)
      .then((response) => {
        //  console.log("RESPONSE", response.data);
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const updateStages = () => {
    // console.log("updateStages start", droppableLists);

    let newList = [];

    Object.keys(droppableLists).map((list, index) => {
      let listId = parseInt(list.replace("LIST_", ""));

      droppableLists[list].items.length >= 1 &&
        droppableLists[list].items.map((item, index2) => {
          console.log("ITEM", item);
          let itemId = parseInt(item.id.replace("_dropped", ""));
          newList.push({
            elementid: itemId,
            stage: listId,
            date: item.date,
            order: index2 + 1,
          });
        });
    });

    // console.log("updateStages send LIST", newList);

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}`,
      data: {
        projectId: id,
        list: newList,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        // console.log("RESPONSE", response.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const addNewStage = (stage, name, start, end) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}newStage`,
      data: {
        projectId: id,
        stage: stage,
        name: name,
        start: start,
        end: end,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        //   console.log("RESPONSE", response.data);
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
        <EditStageData
          isOpen={stageDataOpen}
          closeModal={() => {
            setStageDataOpen(false);
          }}
          data={stageData}
          saveFunc={updateStageDatas}
          deleteStage={deleteStage}
          setVerifyDeleteData={setVerifyDeleteData}
          setVerifyDeleteOpen={setVerifyDeleteOpen}
        />
        <NewStage
          isOpen={newStageOpen}
          closeModal={() => {
            setNewStageOpen(false);
          }}
          saveFunc={addNewStage}
          stages={projectData.etapit}
        />


        <VerifyDelete
          isOpen={verifyDeleteOpen}
          closeModal={() => {
            setVerifyDeleteOpen(false);
          }}
          data={verifyDeleteData}
        />

        <div className="projectmain">
          <div>
            <div className={`project-header-12 projectpage-content ${animation}`}>

              <LeftOutlined className="back-arrow" onClick={() => { navigate(-1) }} />

              {!isMobile ?
                <div className="project-header-smallrow">

                  <p className="project-header-id font-dm-bold">
                    {projectData.tiedot.Tyokohdenro}
                  </p>

                  <p className="project-header-schedule font-dm-bold">
                    {projectData.tiedot?.Aikataulu}
                  </p>
                </div>
                : <></>}

              <h1 className="project-header font-itc">
                {isMobile ?
                  projectData.tiedot.Tyokohdenro
                  : ""} {projectData.tiedot.Tyokohde}
              </h1>
              <div className="project-header-headeritems">
                <div className="item">
                  <p className="label font-dm-regular">{locale.ordered}</p>
                  <p className="value font-dm-bold">
                    {projectData.tiedot.TilattuKplYht || 0}
                  </p>
                </div>

                <div className="item">
                  <p className="label font-dm-regular">{locale.blueprints}</p>
                  <p className="value font-dm-bold">
                    {projectData.tiedot.PiirustuksiaKplYht || 0}
                  </p>
                </div>

                <div className="item">
                  <p className="label font-dm-regular">{locale.prepared}</p>
                  <p className="value font-dm-bold">
                    {projectData.tiedot.ValmistettuKplYht || 0}
                  </p>
                </div>

                <div className="item">
                  <p className="label font-dm-regular">{locale.booked}</p>
                  <p className="value font-dm-bold">
                    {projectData.tiedot.ToimitettuKplYht || 0}
                  </p>
                </div>

                {!isMobile ?
                  <div className="items-right">

                    <div className="screen-buttons">
                      <div className={`screen-button ${screen === 'elements' ? 'selected' : ''}`} onClick={() => setScreen("elements")}>
                        <HomeFilled style={{ fontSize: 20}} />
                      </div>
                       <div className={`screen-button ${screen === 'notes' ? 'selected' : ''}`} onClick={() => setScreen("notes")}>
                        <FileOutlined style={{ fontSize: 20}} />
                      </div> 
                      <div className={`screen-button ${screen === 'info' ? 'selected' : ''}`} onClick={() => setScreen("info")}>
                        <InfoCircleFilled style={{ fontSize: 20}} />
                      </div>
                    </div>
                  </div>
                  : <></>}
              </div>
            </div>


            {screen === 'elements' && <ProjectElements navigate={navigate} projectData={projectData} animation={animation} listType={listType} setListType={setListType} enableEditing={enableEditing} itemsInDroppable={itemsInDroppable} useDragging={useDragging} handleShowDragging={handleShowDragging} />}
          

            
            {screen === 'notes' && <ProjectNotes navigate={navigate} projectData={projectData} getProject={getProject} />}


            {screen === 'info' && <ProjectInfo navigate={navigate} projectData={projectData} getProject={getProject} />}

          </div>

          {/* DRAGGING */}
          {useDragging && (
            <div className={`droppablecontainer ${animation}`}>

              {projectData.etapit.length <= 0 ?
                <>
                  <div className={`droppablecontainer-placeholder`}>
                    <div className={`droppablegroup-placeholder`}>
                      <div className={`droppablebox parent`}  >
                        <div className={`droppablebox-header`}>
                          <div>
                            <p className={`droppablebox-placeholder-header-title font-dm-bold`}>
                              {locale.etaps}
                            </p>

                            <p className={`droppablebox-placeholder-text font-dm-regular`}>
                              {locale.etapintroduction}
                            </p>


                            <button
                              className="buttonstyle font-dm-regular"
                              style={{ marginLeft: 0, marginTop: "1.5em" }}
                              onClick={() => {
                                setNewStageOpen(true);
                              }}
                            >
                              <PlusOutlined style={{ fontSize: 15, marginRight: -5 }} />
                              {locale.etapadd}
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                : <>

                  <div className={`droppablecontainerFixed`}>
                    <div className={`droppablecontainerInner`}>

                      {projectData.etapit.map((etappiItem, indx) => {
                        if (etappiItem.ParentID != 0) return;

                        return (
                          <>
                            <div className={`droppablegroup ${enableEditing}`}>

                              {Object.keys(droppableLists).map((list, i) => {
                                const listdata = droppableLists[list];
                                if (etappiItem.EtappiNro != listdata.parentlist) return;
                                return (
                                  <Droppable
                                    key={list}
                                    droppableId={list}
                                    isDropDisabled={!enableEditing}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          className={`droppablebox ${listdata.parent ? "parent" : ""
                                            }`}
                                        >
                                          <div className={`droppablebox-header`}>
                                            <div>
                                              <p className={`droppablebox-header-title font-dm-bold`}>
                                                {listdata.title}
                                              </p>

                                              <p className={`droppablebox-header-date font-dm-bold`}>
                                                {(listdata.startdate ||
                                                  listdata.enddate) &&

                                                  (listdata.startdate
                                                    ? getParsedDate(
                                                      listdata.startdate
                                                    )
                                                    : "") +
                                                  " - " +
                                                  (listdata.enddate
                                                    ? getParsedDate(
                                                      listdata.enddate
                                                    )
                                                    : "")}
                                              </p>
                                            </div>

                                            {!enableEditing && (
                                              <div className="droppablebox-edit">
                                                <EditOutlined
                                                  key={list}
                                                  id={list}
                                                  className="droppablegroup-icon"
                                                  onClick={() => {
                                                    let itemId = parseInt(
                                                      list.replace("LIST_", "")
                                                    );

                                                    handleOpenEtappiData(
                                                      itemId,
                                                      listdata.title,
                                                      listdata.startdate,
                                                      listdata.enddate,
                                                      listdata.parent
                                                    );
                                                  }}
                                                />
                                              </div>

                                            )}

                                            {/*POIS VIELÄ enableEditing && (
                                          <>
                                            <EditOutlined
                                              key={list}
                                              id={list}
                                              style={{ marginRight: 20, marginLeft: 10, marginTop: 5 }}
                                              onClick={() => {
                                                let itemId = parseInt(
                                                  list.replace("LIST_", "")
                                                );

                                                handleOpenEtappiData(
                                                  itemId,
                                                  listdata.title,
                                                  listdata.startdate,
                                                  listdata.enddate
                                                );
                                              }}
                                            />

                                            <div className="droppablebox-multidate">
                                              <DatePicker
                                                getPopupContainer={(trigger) =>
                                                  trigger.parentElement
                                                }
                                                locale={locale2}
                                                size="small"
                                                value={null}
                                                defaultValue={null}
                                                disabled={!enableEditing}
                                                onChange={(date, dateString) => {
                                                  updateDates(list, dateString);
                                                  //console.log(date, dateString);
                                                }}
                                              />


                                            </div>

                                          </>
                                        ) */}

                                            {/*                                  {!enableEditing && (listdata.parent ? checkParentStageItems(listdata.parentlist) <= 0 : true) && droppableLists[list].items.length <= 0 && (
                                              <div className="droppablebox-delete">
                                                <PlusOutlined
                                                  style={{
                                                    fontSize: 20,
                                                  }}
                                                  className="droppablegroup-icon rotate"
                                                  onClick={() => {



                                                    let itemId = parseInt(
                                                      list.replace("LIST_", "")
                                                    );

                                                    setVerifyDeleteData({
                                                      name: listdata.title,
                                                      parent: listdata.parent,
                                                      verifyFunc: () => {
                                                        deleteStage(itemId);
                                                      },
                                                    });
                                                    setVerifyDeleteOpen(true);

                                                  }}
                                                />
                                              </div>
                                            )} */}
                                          </div>

                                          <div
                                            ref={provided.innerRef}
                                            className="droppableBack"
                                            style={{
                                              backgroundColor:
                                                snapshot.isDraggingOver
                                                  ? "#244cdb3c"
                                                  : "white",
                                            }}
                                          >
                                            <div className="droppableInside">
                                              {droppableLists[list].items.length >=
                                                1
                                                ? droppableLists[list].items.map(
                                                  (item, index) => {
                                                    let itemId = parseInt(
                                                      item.id.replace(
                                                        "_dropped",
                                                        ""
                                                      )
                                                    );

                                                    let rowdata =
                                                      getRowData(itemId);

                                                    /* console.log("DATA:", rowdata, itemId); */

                                                    let elementDate = droppableLists[list].items[index].date
                                                    let isDateBetween = moment(elementDate).isBetween(moment(listdata.startdate), moment(listdata.enddate), 'days', true);

                                                    return (
                                                      <Draggable
                                                        key={item.id}
                                                        draggableId={item.id}
                                                        index={index}
                                                        isDragDisabled={
                                                          !enableEditing
                                                        }
                                                      >
                                                        {(provided, snapshot) => (
                                                          <div
                                                            className="draggableItem"
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={
                                                              provided
                                                                .draggableProps
                                                                .style
                                                            }
                                                          >
                                                            <p
                                                              ro
                                                              className="font-dm-regular draggableItemText">
                                                              {rowdata.Tunnus}

                                                            </p>

                                                            <p className="font-dm-regular draggableItemText2">   {
                                                              rowdata.ValmistusNro
                                                            }</p>


                                                            <DatePicker
                                                              getPopupContainer={(
                                                                trigger
                                                              ) =>
                                                                trigger.parentElement
                                                              }
                                                              placeholder={""}
                                                              format={'DD.MM.YYYY'}
                                                              showWeek={true}
                                                              className={`draggableDatePicker font-dm-regular ${elementDate && !isDateBetween ? 'red' : ''}`}
                                                              size="small"
                                                              value={
                                                                elementDate ? dayjs(elementDate) : null
                                                              }
                                                              disabled={
                                                                !enableEditing
                                                              }
                                                              onChange={(
                                                                date,
                                                                dateString
                                                              ) => {
                                                                let saveDate = dayjs(date).format("YYYY-MM-DD") //Tietokantaa varten tähän tmalliin
                                                                droppableLists[
                                                                  list
                                                                ].items[
                                                                  index
                                                                ].date = saveDate;

                                                                setDroppableLists(
                                                                  {
                                                                    ...droppableLists,
                                                                  }
                                                                );
                                                              }}
                                                            />

                                                            {enableEditing && (
                                                              <PlusOutlined
                                                                style={{
                                                                  fontSize: 20,
                                                                }}
                                                                className="draggableItemClose"
                                                                onClick={() => {
                                                                  removeDraggable(
                                                                    list,
                                                                    item.id
                                                                  );
                                                                }}
                                                              />
                                                            )}
                                                          </div>
                                                        )}
                                                      </Draggable>
                                                    );
                                                  }
                                                )
                                                : null}
                                              {provided.placeholder}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Droppable>
                                );
                              })}
                            </div>
                          </>
                        );
                      })}

                      <></>
                    </div>

                    {!enableEditing && (
                      <div className="etappiBtns">
                        <button
                          className="buttonstyle font-dm-regular"
                          onClick={() => {
                            setNewStageOpen(true);
                          }}
                        >
                          <PlusOutlined style={{ fontSize: 15, marginRight: -5 }} />
                          {locale.etapadd}
                        </button>

                        <button
                          className="buttonstyle font-dm-regular"
                          onClick={() => {
                            setCopiedList(
                              JSON.parse(JSON.stringify(droppableLists))
                            );
                            setEnableEditing(!enableEditing);
                          }}
                        >
                          <MergeCellsOutlined
                            style={{ fontSize: 15, marginRight: -5 }}
                          />
                          {locale.etapcontrol2}
                        </button>
                      </div>
                    )}

                    {enableEditing && (
                      <div className="etappiBtns">
                        <button
                          className="buttonstyle font-dm-regular"
                          style={{
                            backgroundColor: "#25D155",
                            width: "150px",
                          }}
                          onClick={() => {
                            setCopiedList(droppableLists);
                            setEnableEditing(!enableEditing);
                            updateStages();
                            getProject();
                          }}
                        >
                          {locale.save}
                        </button>

                        <button
                          className="buttonstyle font-dm-regular"
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid grey",
                            /*                      borderWidth:1,
                            borderColor: 'grey', */
                            color: "#575757",
                            width: "150px",
                          }}
                          onClick={() => {
                            //console.log("PERUUTA COPIED", copiedList);

                            setDroppableLists(copiedList);
                            setEnableEditing(!enableEditing);
                            getProject();
                          }}
                        >
                          {locale.cancel}
                        </button>
                      </div>
                    )}
                  </div>
                </>}
            </div>
          )}
        </div>
      </DragDropContext>
    </>
  );
};
