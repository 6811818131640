import { useParams } from "react-router-dom";
import { PdfButton } from "../../components/PdfButton/PdfButton";




export const PdfViewer = ({ setToken }) => {
  let { id } = useParams();
  console.log('PdfViewer', id)

  return (
    <>
         <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <iframe
            src={"http://localhost:3000/be9424f0-870f-4dd9-afc8-1fa3fc55f66c"}
            title="PDF"
            height="100%"
            width="100%"
            allowFullScreen
            frameBorder="0"
          />
        </div>
    </>
  );


};
