
import { useEffect, useState } from "react";
import { apiSecure } from "../../../components/api";
import { Loading } from "./../../../components/Loading/Loading";


import { DatePicker, Input, InputNumber, Select } from "antd";
import moment from "moment";
import 'moment/locale/fi';
import dayjs from "dayjs";


const { Option } = Select;
const { RangePicker } = DatePicker;

export const Screen5 = ({
  ModalHeader,
  closeModal,
  data,
  selectedWork,
  setScreen,
  SaveBtn,
}) => {
  const [isLoading, setisLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [text, setText] = useState('');


  const saveData1 = () => {
  
      if (!startDate) return alert("Valitse aloituspäivämäärä");
      if (!endDate) return alert("Valitse lopetuspäivämäärä");
      if (!text) return alert("Lisää selite");


    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `/intra_newOtherEntry`,
      data: {
        worker: data.userId,
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        text: text,
      },
    };

    console.log(req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        //setUsersData(response.data.data);
        setisLoading(false);
        closeModal(true)
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      }); 
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <ModalHeader
        closeModal={() => closeModal()}
        header={"Lisää muu merkintä"}
        user={data.userName}
      />

      <div className="screen3Cont">
        <div>
          <p className="header1 font-dm-bold">Merkinnän tiedot</p>
        </div>

        <div className="screen3Row">
                <p className="font-dm-medium label">Aloitus / Lopetus</p>


                {/* <p className="font-dm-medium value">20.9. - 20.10.2022</p> */}
                <RangePicker
                  className="rangepicker"
                  size="small"
                  suffixIcon={null}
                  value={[startDate, endDate]}
                  onChange={(value) => {
                    setStartDate(value[0]);
                    setEndDate(value[1]);
                  }}
                  onOk={() => {
                    console.log("onOk");
                  }}
                />
              </div>

              <div className="screen3Row">
              <p className="font-dm-medium label">Selite</p>

              <Input
              className="screen4Input"
              value={text}
              onChange={(text) => {
                setText(text.target.value)
                console.log(text.target.value)
              }}
              />

            </div>
      </div>

      <SaveBtn onClick={saveData1} />
    </>
  );
};
