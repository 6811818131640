import { useEffect, useRef, useState } from "react";
import "./Searchbar.css";
import { SearchOutlined, ContactsOutlined, HomeOutlined } from "@ant-design/icons";
import { useTranslate } from "../Translate";
import { Input } from "antd";
import { useDispatch } from "react-redux";
import AllActions from "../../store/actions";
import { useSelector } from "react-redux";
import { getEnvSettings } from "../../config/config";
import { apiSecure } from "../api";

import { LoadingItem } from "../Loading/Loading";
import { useNavigate } from "react-router-dom";


const highlightText = (text, highlight) => {
  if (!highlight) return text;
  if (!text) return ''
  const regex = new RegExp(`(${highlight})`, 'gi');
  return text.replace(regex, '<span class="font-dm-bold">$1</span>');
};

export const Searchbar = () => {
  const dispatch = useDispatch();
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');

  const searchInputRef = useRef(null);
  const dropdownRef = useRef(null);

  const { showSearch } = useSelector((state) => state.currentUser);
  const { selectedFactory } = useSelector((state) => state.currentUser.user);

  const [isLoading, setIsLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);


  const [contactsArr, setContactsArr] = useState([]);
  const [projectsArr, setProjectsArr] = useState([]);

  const [filteredContacts, setFilteredContacts] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);


  // Haku päivittyy, kun hakukentän arvo muuttuu
  useEffect(() => {
    console.log('searchValue', searchValue)

    console.log('contactsArr', contactsArr)
    console.log('projectsArr', projectsArr)

    if (contactsArr.length <= 0 || projectsArr.length <= 0) return

    if (!searchValue) {
      // Jos hakukenttä on tyhjä, tyhjennä myös suodatetut tulokset
      setFilteredContacts([]);
      setFilteredProjects([]);
      return;
    }

    const nContacts = contactsArr.filter(contact => {
      const henkiloMatch = contact?.Henkilo && contact.Henkilo.toLowerCase().includes(searchValue.toLowerCase());
      const yritysMatch = contact?.Yritys && contact.Yritys.toLowerCase().includes(searchValue.toLowerCase());
      const gsmMatch = contact?.GSM && contact.GSM.includes(searchValue);

      // Palautetaan tulos vain, jos joku kentistä vastaa hakuun
      return henkiloMatch || yritysMatch || gsmMatch;
    });

    const nProjects = projectsArr.filter(project => {
      const tyokohdeMatch = project?.Tyokohde && project.Tyokohde.toLowerCase().includes(searchValue.toLowerCase());
      const tyokohdenroMatch = project?.Tyokohdenro && project.Tyokohdenro.toString().includes(searchValue);

      // Palautetaan tulos vain, jos joku kentistä vastaa hakuun
      return tyokohdeMatch || tyokohdenroMatch;
    });


    setFilteredContacts(nContacts)
    setFilteredProjects(nProjects)
  }, [searchValue, contactsArr, projectsArr]);

  //Input focus
  useEffect(() => {
    if (!showSearch) {
      setSearchValue('')
    }
  }, [showSearch]);

  //Input focus
  useEffect(() => {
    if (searchInputRef.current && showSearch) {
      searchInputRef.current.focus();
    }
  }, [searchInputRef, showSearch, isLoading]);





  const closeSearch = () => {
    dispatch(AllActions.userActions.updateShowSearch(true));

  }



  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      console.log('CLICK OUTSIDE')
      closeSearch()
    } else {
      console.log('CLICK INSIDE')
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  const getSearchData = () => {
    console.log("getSearchData");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getSearchData`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        tehdas: selectedFactory
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);

        setDataLoaded(true)
        setContactsArr(response?.data?.data?.yhteystiedot)
        setProjectsArr(response?.data?.data?.tyokohteet)
        setIsLoading(false)
        console.log('getSearchData RESP', response.data.data)
        //setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        //setisLoading(false);
      });
  };

  useEffect(() => {
    if (!showSearch || dataLoaded) return
    getSearchData()
  }, [selectedFactory, showSearch]);



  const openContact = (item) => {

  }


  const openProject = (item) => {
    navigate(`/project/${item.Tyokohdenro}`);
    closeSearch()
  }


  const statusArray = {
    [1]: { name: "Laskennassa", color: '#DEE349' },
    [2]: { name: "Tarjottu", color: '#DEE349' },
    [3]: { name: "Tuotannossa", color: '#2BF263' },
    [4]: { name: "Laskenta Arkisto", color: '#FF0303' },
    [5]: { name: "Tuotanto Arkisto", color: '#FF0303' },
  };


  return (
    <>
      {showSearch ?
        <div className="searchbar" ref={dropdownRef}>
          <div className="searchbar-inside">



            <div className="searchbar-inside-row">
              <div  className="searchbar-icon-box">

              <SearchOutlined className="searchbar-icon" />
              </div>

              {!isLoading ?

                <Input
                  ref={searchInputRef}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={locale.search}
                  className="searchbar-input font-dm-regular"
                /> : <div style={{ marginLeft: 10, marginBottom: 10, marginTop: 5 }}>
                  <LoadingItem color="#fff" /> </div>}
            </div>


            {searchValue.length >= 2 /* && ((filteredContacts.length >= 1) || (filteredProjects.length >= 1)) */ ?
              <div className="searchbar-results">
                <p className="searchbar-results-title font-dm-medium"><ContactsOutlined /> Yhteystiedot</p>

                {filteredContacts.length >= 1 ? filteredContacts.map((itm) => {
                  return (
                    <div key={itm.YhteysNRO} className="searchbar-results-contact font-dm-regular clickable" onClick={() => { openContact(itm) }}>
                      <p
                        className="searchbar-results-contact-name"
                        dangerouslySetInnerHTML={{ __html: highlightText(itm.YhteysNRO + ' ' + itm.Henkilo, searchValue) }}
                      />
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <p dangerouslySetInnerHTML={{ __html: highlightText(itm.Yritys, searchValue) }} className="searchbar-results-contact-company" />
                        <p dangerouslySetInnerHTML={{ __html: highlightText(itm.GSM, searchValue) }} className="searchbar-results-contact-gms" />
                      </div>
                    </div>
                  )
                }) : <><p className="searchbar-results-no-found font-dm-regular">Yhteystietoja ei löytynyt!</p></>}


                <p className="searchbar-results-title font-dm-medium"><HomeOutlined /> Työkohteet</p>
                {filteredProjects.length >= 1 ? filteredProjects.map((itm) => (
                  <div key={itm.Tyokohdenro} className="searchbar-results-project font-dm-regular clickable" onClick={() => { openProject(itm) }}>
                    <p
                      dangerouslySetInnerHTML={{ __html: highlightText(`${itm.Tyokohdenro} ${itm.Tyokohde}`, searchValue) }}
                      className="searchbar-results-project-name"
                    />

                    <p className="searchbar-results-project-status" style={{
                      color: statusArray[itm?.Tila]?.color ? statusArray[itm?.Tila]?.color : '#fff',
                       borderColor: statusArray[itm?.Tila]?.color ? statusArray[itm?.Tila]?.color : '#fff'
                    }}>{statusArray[itm?.Tila]?.name ? statusArray[itm?.Tila]?.name : 'Ei tietoa'}</p>
                  </div>
                )) : <><p className="searchbar-results-no-found font-dm-regular">Työkohteita ei löytynyt!</p></>}


              </div>
              : <></>}
          </div>
        </div>
        : <></>}
    </>
  );
};
