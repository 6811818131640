import { CloseOutlined } from "@ant-design/icons";
import Modal from "react-modal";


//import "./AddWorkModal.css";

const ModalHeader = ({ closeModal, header, user }) => {
  return (
    <div>
      <div className="proj-modalHeaderRow">
        <p className="proj-modalHeader font-dm-bold">{header}</p>
        <CloseOutlined
          className="proj-modalHeaderClose"
          onClick={() => closeModal()}
        />
      </div>
      <p className="proj-modalHeaderUser font-dm-medium">{user}</p>
    </div>
  );
};


export const VerifyDelete = ({ isOpen, closeModal, data={name:null, parent:null, verifyFunc:()=>{}}, saveFunc }) => {
 // console.log("VerifyDelete data", data);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        className="workmodalBase"
        style={{
          overlay: {
            zIndex: 100,
            backgroundColor: "rgba(70, 70, 70, 0.5)",
          },
        }}
        ariaHideApp={false}
      >
        <div className="proj-modalContainer">
          <div className="proj-modal">

          <ModalHeader
              closeModal={() => closeModal()}
              header={data.name}
              user={"Tahdotko poistaa etapin?"}
            />
              <p className="font-dm-bold" style={{margin:0}}>Huom! Tätä ei voi perua.</p>

{data.parent &&
            <p className="font-dm-bold" style={{margin:0}}>Tämä poistaa myös alaetapit!</p>}

          <div className="proj-cancelButtonCont">
          <button className="proj-cancelButton font-dm-regular" onClick={() => {
            closeModal()
          }}>
            Peruuta
          </button>
        </div>

          <div className="proj-saveButtonCont">
          <button className="proj-saveButton font-dm-regular" onClick={() => {
            closeModal()
            data.verifyFunc()
          }}>
            Vahvista
          </button>
        </div>

          </div>
        </div>
      </Modal>
    </>
  );
};
