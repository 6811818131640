import { useEffect, useState } from "react";
import { ProjectsView } from "./ProjectsView";
import "./Schedule.css";
import { TableView } from "./TableView";
import { WorkersView } from "./WorkersView";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import { apiSecure, apiSecureLong } from "../../components/api";
import {
  getParsedDate,
  getParsedDateShort
} from "./../../components/globalFunctions";
import { Loading } from "./../../components/Loading/Loading";

import "../../assets/fonts/DMSans-Bold-bold.js";
import "../../assets/fonts/ITCAvantGardeStd-Bold-bold";

import moment from "moment";
import "moment/locale/fi";
import { drawLine } from "./ScheduleSharedFunc";
import AllActions from "../../store/actions";

export const Schedule = ({ setToken }) => {
  const [pageActive, setPageActive] = useState("henkilosto");
  const dispatch = useDispatch();


  const [isLoading, setisLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);

  const { selectedFactory } = useSelector((state) => state.currentUser.user);


  const [userLines, setUserLines] = useState([]);
  const [installLines, setInstallLines] = useState([]);


  const [projectGroupsOpens, setProjectGroupsOpens] = useState({});
  const [projectBtnAllOpen, setProjectBtnAllOpen] = useState(false);

  const [userGroupsOpens, setUserGroupsOpens] = useState({});
  const [userBtnAllOpen, setUserBtnAllOpen] = useState(false);


  //Jos urliin laitetaan factory tieto niin haetaan suoraan sillä
  let factoryForce = null

  //Jos urliin laitetaan factory tieto niin haetaan suoraan sillä
  //let isScreenshot = false
  const [isScreenshot, setIsScreenshot] = useState(false);

  //Mikä osa näytetään henkilöstö (1,2,3)
  const [part, setPart] = useState(null);



  //Page id
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);


    let pageParam = urlParams.get('page');
    if (pageParam) {
      setPageActive(pageParam)
      urlParams.delete('page');
    }

    //Jos factory urlissa, niin voidaan määritellä että mikä tehdas esitetään
    let factParam = urlParams.get('factory');
    if (factParam) {
      console.log('factoryParam', factParam, !isNaN(factParam))

      //Ei ole numero
      if (isNaN(factParam)) return

      factoryForce = factParam
      urlParams.delete('factory');
    }

    //Jos sc urlissa, niin voidaan määritellä että on screenshot
    let scParam = urlParams.get('sc');
    console.log('sc scParam', scParam)
    if (scParam == '1') {
      console.log('testausta settrue')
      setIsScreenshot(true)
      urlParams.delete('sc');
    }


    //Jos part urlissa, niin voidaan määritellä mitkä ryhmät auki screenshottia varten
    let partParam = urlParams.get('part');
    console.log('part partParam', partParam)
    if (partParam) {
      setPart(partParam)
    }
  }, []);



  //Users
  const updateUserGroupsOpen = (value) => {
    console.log("updateUserGroupsOpen, part", part);
    let datamuut = usersData.muuttyontekijat;
    if (!datamuut) return;

    let newListOpen = {};

    //Jos part urlissa, niin voidaan määritellä mitkä ryhmät auki screenshottia varten
    if (!part) {
      //Tuotanto
      newListOpen["workers"] = value ? value : true;

      //Asennus
      newListOpen["other_Asennus"] = value ? value : false;

      //Muut
      datamuut.map((group, index) => {
        if (group.RyhmaNimi) {
          let groupId = "other_" + group.RyhmaNimi;
          newListOpen[groupId] = value ? value : false;
        }
      });

    } else {

      if (part == 1) {
        newListOpen["workers"] = true;
      }

      if (part == 2) {
        newListOpen["other_Asennus"] = true;
      }

      if (part == 3) {
        datamuut.map((group, index) => {
          let groupId = "other_" + group.RyhmaNimi;
          newListOpen[groupId] = true;
        });
      }

    }

    console.log("Testi", newListOpen)
    setUserGroupsOpens({ ...newListOpen });
  };

  const handleUserBtnOpenAll = () => {
    setUserBtnAllOpen(!userBtnAllOpen);
    updateUserGroupsOpen(!userBtnAllOpen);
  };


  //Projects
  const updateProjectGroupsOpen = (value) => {
    console.log("updateProjectGroupsOpen");
    let dataprojektit = usersData.projektit;
    if (!dataprojektit) return;

    let newListOpen = {};

    //Muut
    dataprojektit.map((project, index) => {
      let groupId = "project_" + project.Tyokohdenro;
      newListOpen[groupId] = value ? value : false;
    });

    setProjectGroupsOpens({ ...newListOpen });
  };

  const handleProjectBtnOpenAll = () => {
    setProjectBtnAllOpen(!projectBtnAllOpen);
    updateProjectGroupsOpen(!projectBtnAllOpen);
  };

  //Creating Groupsopen...
  useEffect(() => {
    if (
      Object.keys(userGroupsOpens).length == 0 &&
      usersData?.muuttyontekijat
    ) {
      console.log("freshNew");
      updateUserGroupsOpen();
      updateProjectGroupsOpen();
    }
  }, [usersData]);

  const getUsersData = () => {
    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `/intra_getusersdata`,
      data: {
        factory: factoryForce ? factoryForce : selectedFactory,
      },
    };

    apiSecureLong(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        setUsersData(response.data.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error at schedule:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getUsersData();
    console.log("getUsersData called", selectedFactory);
  }, [selectedFactory]);

  const takeSc = (type, name) => {
    html2canvas(document.querySelector(`#capture-${pageActive}`)).then(
      (canvas) => {
        let dataURL = canvas.toDataURL("image/png");

        console.log("dataURL", dataURL);

        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "mm",
          format: "a3",
        });

        pdf.setProperties({ title: `Ybt Oy - PDF` });

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const pageRatio = pageWidth / pageHeight;

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const imgRatio = imgWidth / imgHeight;

        console.log("pageRatio", pageRatio);
        console.log("imgRatio", imgRatio);

        let date = getParsedDate(new Date());

        //YBtn logo ja tekstit

        /*         pdf.addImage(logo, "PNG", 23, 52, 38, 11);

        pdf.setFont("ITCAvantGardeStd-Bold", "bold"); // set custom font
        pdf.setFontSize(25);
        pdf.text("Aikataulunajat Projektit", 70, 60);

        pdf.setFont("DMSans-Bold", "bold"); // set custom font
        pdf.setFontSize(10);
        pdf.text(date, 70, 65);

        console.log("Show all font in jsPDF", pdf.getFontList()); */

        //Joku valmis image ratio systeemi
        if (imgRatio >= 1) {
          const wc = imgWidth / pageWidth;
          if (imgRatio >= pageRatio) {
            pdf.addImage(
              dataURL,
              "PNG",
              0,
              (pageHeight - imgHeight / wc) / 2,
              pageWidth,
              imgHeight / wc,
              null,
              "NONE"
            );
          } else {
            const pi = pageRatio / imgRatio;
            pdf.addImage(
              dataURL,
              "PNG",
              (pageWidth - pageWidth / pi) / 2,
              0,
              pageWidth / pi,
              imgHeight / pi / wc,
              null,
              "NONE"
            );
          }
        } else {
          const wc = imgWidth / pageHeight;
          if (1 / imgRatio > pageRatio) {
            const ip = 1 / imgRatio / pageRatio;
            const margin = (pageHeight - imgHeight / ip / wc) / 4;
            pdf.addImage(
              dataURL,
              "PNG",
              (pageWidth - imgHeight / ip / wc) / 2,
              -(imgHeight / ip / wc + margin),
              pageHeight / ip,
              imgHeight / ip / wc,
              null,
              "NONE",
              -90
            );
          } else {
            pdf.addImage(
              dataURL,
              "PNG",
              (pageWidth - imgHeight / wc) / 2,
              -(imgHeight / wc),
              pageHeight,
              imgHeight / wc,
              null,
              "NONE",
              -90
            );
          }
        }

        //pdf.addImage(dataURL, 'PNG', 0, 0, width+100, height);
        //pdf.save(`${name}.pdf`);

        let fileUrl = pdf.output("bloburi");
        console.log("fileUrl", fileUrl);
        //const fileURL = window.URL.createObjectURL(response.data);
        window.open(fileUrl);
      }
    );
  };

  //Tables to Timeline
  useEffect(() => {
    if (usersData.tyontekijasisalto || usersData.lomat) {
      let newuserWorks = [];
      let newGroups = [];
      let newItems = [];

      let newUserLines = [];
      let newInstallLines = [];

      let datasisalto = usersData.tyontekijasisalto;
      let datalomat = usersData.lomat;
      let datapyhat = usersData.arkipyhat;
      let datamuut = usersData.muuttyontekijat;

      let dataasennustekijat = usersData.asennustekijat;
      let dataasennusrivit = usersData.asennusrivit;

      let lastLineEnd = {
        user: null,
        date: null,
      };

      newGroups.push({
        id: "workers",
        title: "Tuotanto",
        subtitle: true,
        //height: 80,
      });

      datasisalto.map((row, index) => {
        //if (row.TyontekijaNro != 123) return;

        let userTableId = "user_" + row.TyontekijaNro;

        //Create group if not exists
        if (
          !Object.keys(newUserLines).some((id) => {
            let data = newUserLines[id];
            //console.log('base data123', data)
            return data.id === row.TyontekijaNro;
          })
        ) {
          newUserLines[userTableId] = {
            id: row.TyontekijaNro,
            name: row.TyontekijaNimi,
            data: [],
          };
        }

        if (row.AikajananPituus) {
          //console.log(row.TyontekijaNimi, row.KirjausId, row.EdellinenTyo);
          ////console.log("lastLineEnd", lastLineEnd);

          let currDate =
            lastLineEnd.user == row.TyontekijaNro && lastLineEnd.date != null
              ? moment(lastLineEnd.date).add(1, "days")
              : moment();

          let lineStart = moment(currDate);
          let lineEnd = moment(currDate).add(row.AikajananPituus, "days");
          let lineLength = row.AikajananPituus;
          let lastVacationEnd = null;

          //Tarkistetaan lomat, jos löytyy stopataan viiva siihen ja jatketaan loman jälkeen
          datalomat.map((vacation, index) => {
            if (vacation.TyontekijaNro != row.TyontekijaNro) return;

            //Tarkistetaan alkaako loma samaanaikaan kuin kuormitus
            let sameStartDay =
              getParsedDate(vacation.AlkuPvm) == getParsedDate(lineStart);

            //Jos loman aloituspäivä on viivan välissä
            if (
              moment(vacation.AlkuPvm).isBetween(lineStart, lineEnd) ||
              sameStartDay
            ) {
              //Tarkistetaan tuleeko lomat peräkkäin
              let lastVEnd = getParsedDate(
                moment(lastVacationEnd).add(1, "days")
              );
              let currVStart = getParsedDate(moment(vacation.AlkuPvm));
              let inRow = lastVEnd == currVStart;

              if (!inRow && !sameStartDay) {
                //Lisätään viiva tähän mennessä

                newUserLines[userTableId].data.push({
                  id: row.KirjausId,
                  title: row.Tyokohde + " | " + row.ElementtiRyhma,
                  start: lineStart,
                  end: moment(vacation.AlkuPvm).add(-1, "days"),
                  type: "work",
                  table: row.PoytaNro,
                  project: row.Tyokohdenro,
                  data: {
                    workId: row.Tyokohdenro,
                    data: {
                      name: row.Tyokohde,
                      id: row.Tyokohdenro,
                      elementgroup: row.ElementtiRyhma,
                      table: row.Poyta,
                      percent: row.KuormitusOsuus,
                      kpl: row.ValmistamattaKpl,
                      multiplier: row.HenkilokohtainenKerroin,
                      pvmReady: null,
                    },
                  },
                });

                /*                drawLine(
                  newItems,
                  row.TyontekijaNro,
                  row.Tyokohde + " | " + row.ElementtiRyhma,
                  lineStart,
                  moment(vacation.AlkuPvm).add(-1, "days"),
                  "work",
                  {
                    workId: row.Tyokohdenro,
                    rowId: row.KirjausId,
                    data: {
                      name: row.Tyokohde,
                      elementgroup: row.ElementtiRyhma,
                      percent: row.KuormitusOsuus,
                      kpl: row.ValmistamattaKpl,
                      multiplier: row.HenkilokohtainenKerroin,
                      pvmReady: null,
                    },
                  }
                ); */

                //Vähennetään loppukuormituksen pituudesta jo piirretty pituus
                let currLineLength = moment(vacation.AlkuPvm).diff(
                  moment(lineStart),
                  "days"
                );

                lineLength = lineLength - currLineLength;
              }

              //Piirretään loman viiva

              newUserLines[userTableId].data.push({
                title: vacation.PoissaoloSyy,
                start: vacation.AlkuPvm,
                end: vacation.LoppuPvm,
                type: "holiday",
              });

              /*               drawLine(
                newItems,
                row.TyontekijaNro,
                vacation.PoissaoloSyy,
                vacation.AlkuPvm,
                vacation.LoppuPvm,
                "vacation",
                {
                  vacationId: vacation.PoissaoloNro,
                  data: {
                    name: vacation.PoissaoloSyy,
                    startdate: getParsedDateShort(vacation.AlkuPvm),
                    enddate: getParsedDate(vacation.LoppuPvm),
                    durationD: vacation.KestoPv,
                    durationH: vacation.KestoH,
                  },
                }
              ); */

              //Päivitetään loman lopetuspäivä
              lastVacationEnd = moment(vacation.LoppuPvm);

              //Seuraavan aikajanapätkän alku on kyseisen poissaolomerkinnän LoppuPvm + 1.
              lineStart = moment(vacation.LoppuPvm).add(1, "days");

              //Seuraavan aikajanapätkän loppu on kyseisen poissaolomerkinnän LoppuPvm + aikajanapätkän jäljelle jäävä määrä
              lineEnd = moment(vacation.LoppuPvm).add(lineLength, "days");
            }
          });

          //Tarkistetaan lomat, jos löytyy stopataan viiva siihen ja jatketaan loman jälkeen
          datapyhat.map((day, index) => {
            //Tarkistetaan alkaako loma samaanaikaan kuin kuormitus
            let sameStartDay =
              getParsedDate(day.ArkipyhaPvm) == getParsedDate(lineStart);

            //Jos loman aloituspäivä on viivan välissä
            if (
              moment(day.ArkipyhaPvm).isBetween(lineStart, lineEnd) ||
              sameStartDay
            ) {
              //console.log("Loma osuu kuormitukselle");

              //Tarkistetaan tuleeko lomat peräkkäin
              let lastVEnd = getParsedDate(
                moment(lastVacationEnd).add(1, "days")
              );
              let currVStart = getParsedDate(moment(day.ArkipyhaPvm));
              let inRow = lastVEnd == currVStart;

              if (!inRow && !sameStartDay) {
                newUserLines[userTableId].data.push({
                  id: row.KirjausId,
                  title: row.Tyokohde + " | " + row.ElementtiRyhma,
                  start: lineStart,
                  end: moment(day.ArkipyhaPvm).add(-1, "days"),
                  type: "work",
                  table: row.PoytaNro,
                  project: row.Tyokohdenro,
                  data: {
                    workId: row.Tyokohdenro,
                    data: {
                      name: row.Tyokohde,
                      id: row.Tyokohdenro,
                      elementgroup: row.ElementtiRyhma,
                      table: row.Poyta,
                      percent: row.KuormitusOsuus,
                      kpl: row.ValmistamattaKpl,
                      multiplier: row.HenkilokohtainenKerroin,
                      pvmReady: null,
                    },
                  },
                });

                /*                 drawLine(
                  newItems,
                  row.TyontekijaNro,
                  row.Tyokohde + " | " + row.ElementtiRyhma,
                  lineStart,
                  moment(day.ArkipyhaPvm).add(-1, "days"),
                  "work",
                  {
                    workId: row.Tyokohdenro,
                    rowId: row.KirjausId,
                    data: {
                      name: row.Tyokohde,
                      elementgroup: row.ElementtiRyhma,
                      percent: row.KuormitusOsuus,
                      kpl: row.ValmistamattaKpl,
                      multiplier: row.HenkilokohtainenKerroin,
                      pvmReady: null,
                    },
                  }
                ); */

                //Vähennetään loppukuormituksen pituudesta jo piirretty pituus
                let currLineLength = moment(day.ArkipyhaPvm).diff(
                  moment(lineStart),
                  "days"
                );

                lineLength = lineLength - currLineLength;
              }

              newUserLines[userTableId].data.push({
                title: day.Selite,
                start: day.ArkipyhaPvm,
                end: day.ArkipyhaPvm,
                type: "holiday",
              });

              //Pyhäpäivä viiva
              /*               drawLine(
                newItems,
                row.TyontekijaNro,
                day.Selite,
                day.ArkipyhaPvm,
                day.ArkipyhaPvm,
                "holiday",
                {
                  holidayId: day.ArkipyhaId,
                  data: {
                    name: day.Selite,
                    date: getParsedDate(day.ArkipyhaPvm),
                  },
                }
              ); */

              //Päivitetään loman lopetuspäivä
              lastVacationEnd = moment(day.ArkipyhaPvm);

              //Seuraavan aikajanapätkän alku on kyseisen poissaolomerkinnän LoppuPvm + 1.
              lineStart = moment(day.ArkipyhaPvm).add(1, "days");

              //Seuraavan aikajanapätkän loppu on kyseisen poissaolomerkinnän LoppuPvm + aikajanapätkän jäljelle jäävä määrä
              lineEnd = moment(day.ArkipyhaPvm).add(lineLength, "days");
            }
          });

          //Jos lopetuspv on perjantai tai lauantai -> siirretään sunnuntaille.
          //console.log("LopetusPv", moment(lineEnd).day());
          let endDay = moment(lineEnd).day(); // 5 = perjantai,6 = lauantai
          if (endDay == 5) lineEnd = moment(lineEnd).add(2, "days");
          if (endDay == 6) lineEnd = moment(lineEnd).add(1, "days");

          //koko / loppupätkä
          newUserLines[userTableId].data.push({
            id: row.KirjausId,
            title: row.Tyokohde + " | " + row.ElementtiRyhma,
            start: lineStart,
            end: lineEnd,
            type: "work",
            table: row.PoytaNro,
            project: row.Tyokohdenro,
            data: {
              workId: row.Tyokohdenro,
              data: {
                name: row.Tyokohde,
                id: row.Tyokohdenro,
                elementgroup: row.ElementtiRyhma,
                table: row.Poyta,
                percent: row.KuormitusOsuus,
                kpl: row.ValmistamattaKpl,
                multiplier: row.HenkilokohtainenKerroin,
                pvmReady: null,
              },
            },
          });

          /*           drawLine(
            newItems,
            row.TyontekijaNro,
            row.Tyokohde + " | " + row.ElementtiRyhma,
            lineStart,
            lineEnd,
            "work",
            {
              workId: row.Tyokohdenro,
              rowId: row.KirjausId,
              data: {
                name: row.Tyokohde,
                elementgroup: row.ElementtiRyhma,
                percent: row.KuormitusOsuus,
                kpl: row.ValmistamattaKpl,
                multiplier: row.HenkilokohtainenKerroin,
                pvmReady: null,
              },
            }
          ); */

          //Päivitetään pätkän LoppuPvm
          lastLineEnd = {
            user: row.TyontekijaNro,
            date: lineEnd,
          };

          //Päivitetään arvioitu valmistuminen kaikkiin riveihin tooltippiä varten
          let shortEnd = getParsedDateShort(lineEnd);

          newUserLines[userTableId].data.map((itemrow, index) => {
            if (itemrow.id == row.KirjausId) {
              console.log("samaa työtä2:", itemrow);
              itemrow.data.data.pvmReady = shortEnd;
            }
          });

          //Tallennetaan työ tauluun. Käytetään modalistta "Edelliset työt"
          let userId = "user_" + row.TyontekijaNro;
          if (!newuserWorks[userId]) newuserWorks[userId] = [];

          newuserWorks[userId].push({
            KirjausId: row.KirjausId,
            workid: row.Tyokohdenro,
            name: row.Tyokohde,
            endDate: lineEnd,
          });
        }

        //Jos työsuhde päättynyt
        if (row.ResurssinPaattymisPvm) {
          /*console.log(
            "row.ResurssinPaattymisPvm",
            row,
            row.ResurssinPaattymisPvm
          );*/

          newUserLines[userTableId].data.push({
            title: "Työsuhde päättynyt",
            start: moment(row.ResurssinPaattymisPvm).add(1, "days"),
            end: moment(row.ResurssinPaattymisPvm).add(1, "year"),
            type: "jobended",
          });

          /*           drawLine(
            newItems,
            row.TyontekijaNro,
            "Työsuhde päättynyt",
            moment(row.ResurssinPaattymisPvm).add(1, "days"),
            moment(row.ResurssinPaattymisPvm).add(1, "year"),
            "jobended"
          ); */
        }

        /*  }  */

        /*
                //Muut pyhät
                datapyhat.map((day, index) => {
                  if (day.ArkipyhaPvm) {
                    newItems.push({
                      id: newItems.length + 1,
                      group: row.TyontekijaNro,
                      title: day.Selite || "Syytä ei ilmoitettu",
                      vacation: false,
                      className: 'item-type-dayoff',
                      start_time: formatLineStart(day.ArkipyhaPvm),
                      end_time: formatLineEnd(day.ArkipyhaPvm),
                    });
                  }
                }); */
      });

      console.log("datamuut", datamuut);

      //Luodaan muut työntekijät/groupit
      datamuut.map((group, index) => {
        let groupId = "other_" + group.RyhmaNimi;

        console.log("muutrow", group.RyhmaNimi, group.TyontekijaNimi);

        //Tarkistetaan löytyykö jo
        if (!newGroups.some((project) => project.id === groupId)) {
          console.log("muutrow create", group.RyhmaNimi);

          newGroups.push({
            id: groupId,
            title: group.RyhmaNimi,
            isOpenableProject: true,
            subtitle: true,
            /* height: 80, */
          });
        }
      });

      //
      //  ASENNUS
      //

      dataasennustekijat.map((worker, index) => {
        console.log("asennustekijat", worker);

        let userTableId = "worker_" + worker.TyontekijaNro;

        if (
          !Object.keys(newInstallLines).some((id) => {
            let data = newInstallLines[id];
            //console.log('base data123', data)
            return data.id === worker.TyontekijaNro;
          })
        ) {
          newInstallLines[userTableId] = {
            id: worker.TyontekijaNro,
            name: worker.TyontekijaNimi,
            data: [],
          };
        }
      });

      dataasennusrivit.map((row, index) => {
        let haveProject = false
        let userTableId = "worker_" + row.Tyontekija;


        if (!newInstallLines[userTableId]?.id) {
          console.log('newInstallLines Not found!!', row.Tyontekija, row)
          return
        }

        if (row.Tyokohde) haveProject = true

        let lineStart = row.Alku
        let lineEnd = row.Loppu
        let lastVacationEnd = null;


        datapyhat.map((day, index) => {
          //Tarkistetaan alkaako loma samaanaikaan kuin kuormitus
          let sameStartDay =
            getParsedDate(day.ArkipyhaPvm) == getParsedDate(lineStart);

          //Jos loman aloituspäivä on viivan välissä
          if (
            moment(day.ArkipyhaPvm).isBetween(lineStart, lineEnd) ||
            sameStartDay
          ) {

            //Tarkistetaan tuleeko lomat peräkkäin
            let lastVEnd = getParsedDate(
              moment(lastVacationEnd).add(1, "days")
            );
            let currVStart = getParsedDate(moment(day.ArkipyhaPvm));
            let inRow = lastVEnd == currVStart;

            if (!inRow && !sameStartDay) {

              /*               drawLine(
                    newItems,
                    row.Tyontekija,
                    haveProject ? row.TyokohdeNimi + ' | ' +row.Sisalto : row.Sisalto,
                    lineStart,
                    moment(day.ArkipyhaPvm).add(-1, "days"),
                    haveProject ? "install_proj" : 'install',
                    {
                      data: {
                        name: (haveProject ? row.TyokohdeNimi : row.Sisalto),
                        text: (haveProject ? row.Sisalto : ''),
                        date: getParsedDate(row.Alku) + ' - '+ getParsedDate(row.Loppu),
                      }
                    }
                  ); */

              newInstallLines[userTableId].data.push({
                id: row.AsennusId,
                title: haveProject ? row.TyokohdeNimi + ' | ' + row.Sisalto : row.Sisalto,
                start: lineStart,
                end: moment(day.ArkipyhaPvm).add(-1, "days"),
                type: haveProject ? "install_proj" : 'install',
                project: row.Tyokohde,
                data: {
                  data: {
                    name: (haveProject ? row.TyokohdeNimi : row.Sisalto),
                    text: (haveProject ? row.Sisalto : ''),
                    date: getParsedDate(row.Alku) + ' - ' + getParsedDate(row.Loppu),
                  }
                },
              });

            }

            //Pyhäpäivä viiva
            newInstallLines[userTableId].data.push({
              id: row.Tyontekija,
              title: day.Selite,
              start: day.ArkipyhaPvm,
              end: day.ArkipyhaPvm,
              type: "holiday",
              data: {
                holidayId: day.ArkipyhaId,
                data: {
                  name: day.Selite,
                  date: getParsedDate(day.ArkipyhaPvm),
                },
              },
            });


            //Päivitetään loman lopetuspäivä
            lastVacationEnd = moment(day.ArkipyhaPvm);

            //Seuraavan aikajanapätkän alku on kyseisen poissaolomerkinnän LoppuPvm + 1.
            lineStart = moment(day.ArkipyhaPvm).add(1, "days");
          }
        });


        /*         drawLine(
                  newItems,
                  row.Tyontekija,
                  haveProject ? row.TyokohdeNimi + ' | ' +row.Sisalto : row.Sisalto,
                  lineStart,
                  lineEnd,
                  haveProject ? "install_proj" : 'install',
                  {
                    data: {
                      name: (haveProject ? row.TyokohdeNimi : row.Sisalto),
                      text: (haveProject ? row.Sisalto : ''),
                      date: getParsedDate(row.Alku) + ' - '+ getParsedDate(row.Loppu),
                    }
                  }
                ); */



        newInstallLines[userTableId].data.push({
          id: row.AsennusId,
          title: haveProject ? row.TyokohdeNimi + ' | ' + row.Sisalto : row.Sisalto,
          start: lineStart,
          end: lineEnd,
          type: haveProject ? "install_proj" : 'install',
          project: row.Tyokohde,
          data: {
            data: {
              name: (haveProject ? row.TyokohdeNimi : row.Sisalto),
              text: (haveProject ? row.Sisalto : ''),
              date: getParsedDate(row.Alku) + ' - ' + getParsedDate(row.Loppu),
            }
          },
        });


      });




      //Muut lomat
      datalomat.map((vacation, index) => {
        if (vacation.AlkuPvm && vacation.LoppuPvm) {
          if (
            newItems.some((item) => item.vacationId === vacation.PoissaoloNro)
          )
            return;

          console.log("muuloma", vacation);

          //Jos aloitusPvm vanhempi kuin nykyinen pvm, asetetaan nykyinen
          //let newStartDate = vacation.AlkuPvm;
          //if (moment(vacation.AlkuPvm) < moment()) newStartDate = moment();

          drawLine(
            newItems,
            vacation.TyontekijaNro,
            vacation.PoissaoloSyy,
            vacation.AlkuPvm,
            vacation.LoppuPvm,
            "vacation",
            {
              vacationId: vacation.PoissaoloNro,
              data: {
                name: vacation.PoissaoloSyy,
                startdate: getParsedDateShort(vacation.AlkuPvm),
                enddate: getParsedDate(vacation.LoppuPvm),
                durationD: vacation.KestoPv,
                durationH: vacation.KestoH,
              },
            }
          );
        }
      });

      //Muut pyhät
      newGroups.map((userGroup, index) => {
        console.log("userGroup", userGroup);

        //Tarkistetaan lomat, jos lomaa ei ole vielä piirretty > piirretään
        datapyhat.map((day, index) => {
          if (
            newItems.some(
              (item) =>
                (item.holidayId === day.ArkipyhaId &&
                  item.group === userGroup.id) ||
                userGroup.subtitle
            )
          )
            return;

          //Pyhäpäivä viiva
          drawLine(
            newItems,
            userGroup.id,
            day.Selite,
            day.ArkipyhaPvm,
            day.ArkipyhaPvm,
            "holiday",
            {
              holidayId: day.ArkipyhaId,
              data: {
                name: day.Selite,
                date: getParsedDate(day.ArkipyhaPvm),
              },
            }
          );
        });
      });

      //console.log("userWorks", newuserWorks);
      console.log("base newGroups", newGroups);
      console.log("base newItems", newItems);
      console.log("base newUserLines", newUserLines);

      setUserLines(newUserLines);
      setInstallLines(newInstallLines);

      console.log("newInstallLines", newInstallLines);

      //setUserWorks(newuserWorks);
      //setGroupsData(newGroups);
      //setItemsData(newItems);
    }
  }, [usersData]);

  if (isLoading) {
    return <Loading />;
  }



  return (
    <>

      <div className="selector">
        
        <div className="selector-group">
          <div
            className={`selector-item font-dm-medium ${pageActive == "projektit" && "active"
              }`}
            onClick={() => setPageActive("projektit")}
          >
            <p className="selector-label">Projektit</p>
          </div>
          <div
            className={`selector-item font-dm-medium ${pageActive == "paikat" && "active"
              }`}
            onClick={() => setPageActive("paikat")}
          >
            <p className="selector-label">Tuotantopaikat</p>
          </div>{" "}
          <div
            className={`selector-item font-dm-medium ${pageActive == "henkilosto" && "active"
              }`}
            onClick={() => setPageActive("henkilosto")}
          >
            <p className="selector-label">Henkilöstö</p>
          </div>
        </div>

        <div className="buttonPos">
          <button className="buttonstyle" onClick={() => takeSc("my-content")}>
            PDF
          </button>
        </div>
        

      </div>

      <div className="outerContainer">
        {
          pageActive == "henkilosto" && (
            /*  <div id={`capture-henkilosto`}> */
            <WorkersView
              usersData={usersData}
              getUsersData={getUsersData}
              groupsOpen={userGroupsOpens}
              setGroupsOpen={setUserGroupsOpens}
              handleBtnOpenAll={handleUserBtnOpenAll}
              btnAllOpen={userBtnAllOpen}
              part={part}
            />
          )
          /*   </div> */
        }

        {
          pageActive == "paikat" && (
            /*  <div id={`capture-paikat`}> */

            <TableView
              usersData={usersData}
              getUsersData={getUsersData}
              userLines={userLines}
              isScreenshot={isScreenshot}
            />
          )
          /*  </div> */
        }

        {
          pageActive == "projektit" && (
            /*   <div id={`capture-projektit`}> */
            <ProjectsView
              usersData={usersData}
              getUsersData={getUsersData}
              groupsOpen={projectGroupsOpens}
              setGroupsOpen={setProjectGroupsOpens}
              handleBtnOpenAll={handleProjectBtnOpenAll}
              btnAllOpen={projectBtnAllOpen}
              userLines={userLines}
              installLines={installLines}
            />
          )
          /*  </div> */
        }
      </div>
    </>
  );
};
