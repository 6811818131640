let languages = {
    ['fi'] : {
        factory_1: 'Ylitornio',
        factory_2: 'Raahe',
        factory_3: 'Kuhmo',
        finland:'FI',
        sweden:'SE',
        english: "EN",

        slogan:'Parasta Betonista',
        deliveries:'Toimitukset',

        loginerror:'Käyttäjätunnus tai salasana väärin!',
        login: 'Kirjaudu sisään',
        loginbtn: 'Kirjaudu sisään',
        loginbtnprogress: 'Kirjaudutaan...',
        logout: 'Kirjaudu ulos',
        username: 'Käyttäjätunnus',
        password: 'Salasana',
        insertusername: 'Syötä käyttäjätunnus!',
        insertpassword: 'Syötä salasana!',
        termstext: 'Käyttäessäsi palvelua hyväksyt palvelun',
        terms: 'Käyttöehdot',
        and: 'ja',
        privacy: 'Tietosuojakäytännöt.',

        production: 'Tuotanto',
        delivery: 'Toimitukset',
        inst_order: 'Asennusjärjestys',

        deliveryheader: 'Toimitus',


        isready: 'Valmiit',

        projects: 'Projektit',
        contacts: 'Yhteystiedot',

        ordered: 'Tilattu',
        blueprints: 'Piirustuksia',
        prepared: 'Valmistettu',
        booked: 'Toimitettu',

        schedule: "Aikataulu",

        pdfreport: "PDF raportti",
        etapcontrol: "Etappien hallinta",
        etapcontrol2: "Hallitse etappeja",
        etapcontrolhide: "Piilota etapit",
        etaps: "Etapit",
        etapintroduction: "Luomalla etappeja pystyt järjestämään tilauksesi tuotteet erillisiin etappeihin ja määrittämään etapeille aikataulun sekä etappien sisäisen asennusjärjestyksen.",
        etapadd: "Lisää etappi",
        etapedit: "Etapin muokkaus",
        etapeditname: "Nimi",
        etapeditdate: "Valitse toimituksen alku/loppu",
        etapeditremove: "Poista etappi",


        elementgroups: "Elementtiryhmät",


        tunnus:"Tunnus",
        id: "ID",
        pituus: "Pituus",
        korkeus: "Korkeus",
        paksuus:"Paksuus",
        m2: "Brutto m2",
        paino: "Paino",
        piirustus: "Piirustus",
        valmistus: "Valmistus",
        toimitus: "Toimitus",
        etap: "Etappi",
        factory: "Tehdas",
        waybill: "Kuormakirja",

        noscheduletext: "Elementtikohtaista aikataulua ei määritetty",

        cancel: "Peruuta",
        save: "Tallenna",

        noprojects: "Projekteja ei löytynyt!",
        nophases: "Ei etappia valittu",

        map: "Kartta",
        search: "Hae"
    },
    ['se']: {
        factory_1: 'Ylitornio',
        factory_2: 'Raahe',
        factory_3: 'Kuhmo',
        finland:'FI',
        sweden:'SE',
        english: "EN",
    
        slogan: 'Det Bästä av Betong',
        deliveries: 'Leveranser',
    
        loginerror: 'Fel användarnamn eller lösenord!',
        login: 'Logga in',
        loginbtn: 'Logga in',
        loginbtnprogress: 'Loggar in...',
        logout: 'Logga ut',
        username: 'Användarnamn',
        password: 'Lösenord',
        insertusername: 'Ange användarnamn!',
        insertpassword: 'Ange lösenord!',
        termstext: 'Genom att använda tjänsten godkänner du',
        terms: 'Användarvillkoren',
        and: 'och',
        privacy: 'Dataskyddspolicyn.',
    
        production: 'Produktion',
        delivery: 'Leveranser',
        inst_order: 'Montering',
    
        deliveryheader: 'Leverans',
    
        isready: 'Färdiga',
    
        projects: 'Projekter',
        contacts: 'Kontakter',
    
        ordered: 'Beställd',
        blueprints: 'Ritningar',
        prepared: 'Tillverkad',
        booked: 'Levererad',
    
        schedule: "Tidsplan",
    
        pdfreport: "PDF rapport",
        etapcontrol: "Etappkontroll",
        etapcontrol2: "Kontrollera etapper",
        etapcontrolhide: "Dölj etapper",
        etaps: "Etapper",
        etapintroduction: "Genom att skapa etapper kan du organisera dina produkter i etapper och kontrollera tidplan och montering ordning.",
        etapadd: "Lägg till etapp",
        etapedit: "Redigera etapp",
        etapeditname: "Namn",
        etapeditdate: "Välj start/slutdatum för leverans",
        etapeditremove: "Ta bort etapp",
    
        elementgroups: "Elementgrupper",
    
        tunnus: "Littera",
        id: "ID",
        pituus: "Längd",
        korkeus: "Höjd",
        paksuus: "Tjocklek",
        m2: "Brutto m2",
        paino: "Vikt",
        piirustus: "Ritning",
        valmistus: "Tillverkning",
        toimitus: "Leverans",
        etap: "Etapp",
        factory: "Fabrik",
        waybill: "Fraktsedel",
    
        noscheduletext: "Inget elementspecifikt tidsplan",
    
        cancel: "Avbryt",
        save: "Spara",

        noprojects: "Inga projekt hittades!",
        nophases: "Nej etapp valt",

        map: "Karta",
        search: "Sök"
    },
    ['en']: {
        factory_1: 'Ylitornio',
        factory_2: 'Raahe',
        factory_3: 'Kuhmo',
        finland:'FI',
        sweden:'SE',
        english: "EN",
    
        slogan: 'The Best in Concrete',
        deliveries: 'Deliveries',
    
        loginerror: 'Username or password incorrect!',
        login: 'Log in',
        loginbtn: 'Log in',
        loginbtnprogress: 'Logging in...',
        logout: 'Log out',
        username: 'Username',
        password: 'Password',
        insertusername: 'Enter username!',
        insertpassword: 'Enter password!',
        termstext: 'By using the service, you agree to the',
        terms: 'Terms of Use',
        and: 'and',
        privacy: 'Privacy Policies.',
    
        production: 'Production',
        delivery: 'Deliveries',
        inst_order: 'Erection',
    
        deliveryheader: 'Delivery',
    
        isready: 'Ready',
    
        projects: 'Projects',
        contacts: 'Contacts',
    
        ordered: 'Ordered',
        blueprints: 'Drawings',
        prepared: 'Casted',
        booked: 'Delivered',
    
        schedule: "Schedule",
    
        pdfreport: "PDF report",
        etapcontrol: "Phase control",
        etapcontrol2: "Manage phases",
        etapcontrolhide: "Hide phases",
        etaps: "Phases",
        etapintroduction: "By creating phases, you can organize your products into phases and set a schedule and erection order.",
        etapadd: "Add phase",
        etapedit: "Edit phase",
        etapeditname: "Name",
        etapeditdate: "Select start/end date for delivery",
        etapeditremove: "Remove phase",
    
        elementgroups: "Element groups",
    
        tunnus: "DWG. NO.",
        id: "ID",
        pituus: "Length",
        korkeus: "Height",
        paksuus: "Thickness",
        m2: "Gross m2",
        paino: "Weight",
        piirustus: "Drawing",
        valmistus: "Casted",
        toimitus: "Delivery",
        etap: "Phase",
        factory: "Factory",
        waybill: "Waybill",
    
        noscheduletext: "No element-specific schedule was defined",
    
        cancel: "Cancel",
        save: "Save",

        noprojects: "No projects found!",
        nophases: "No phase selected",

        map: "Map",
        search: "Search"
    }
}

export default languages