import "./PdfButton.css";
import { FilePdfOutlined } from "@ant-design/icons";
import { apiSecure } from "../api";
import { useEffect, useState } from "react";
import { LoadingItem } from "../Loading/Loading";



export const getPdf = async (url, data) => {
  console.log("getPdf", url, data);
  let req_config = {
    method: "POST",
    url: url,
    data: data,
    responseType: "blob",
    headers: {
      Accept: "application/pdf",
    },
  };

  try {
    const response = await apiSecure(req_config);
    console.log(response);
    const fileURL = window.URL.createObjectURL(response.data);
    window.open(fileURL);
    return true;
  } catch (error) {
    console.log("Error:", error);
    return false;
  }
};

export function PdfButton({ url = "", data = {} }) {
  const [loadingPdf, setLoadingPdf] = useState(false);

  return (
    <>
      <div
        className="pdf-btn"
        onClick={async () => {
          setLoadingPdf(true)
          await getPdf(url, data);
          setLoadingPdf(false)
        }}
      >
        {loadingPdf ? <LoadingItem color={"black"} /> :
          <FilePdfOutlined style={{ fontSize: 25 }} />
        }
      </div>
    </>
  );
}
