const initialState = {
  user: {
    lang: 'fi',
    selectedFactory: '1',
    userid: 0,
    tunnus: "",
    name: "",
    yritys: "",
  },
  response_type: "",
  loggingIn: false,
  loggedIn: false,
  userLoaded: false,
  showSearch: false,
};

// Use the initialState as a default value
export default function userReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions

    case "USER_LOADED":
      return {
        ...state,
        userLoaded: true,
      };

    case "LOGIN_START":
      return {
        ...state,
        loggingIn: true
      };

    case "LOGIN_STOP":
      return {
        ...state,
        loggingIn: false
      };

    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: { ...state.user, ...action.payload},
        loggedIn: true,
        userLoaded: true,
        response_type: "success",
      };

    case "LOGIN_FAILED":
      return {
        ...state,
        response_type: action.payload,
      };

    case "LOG_OUT":
      return {
        ...initialState,
        user:{
          ...initialState.user,
          lang: state.user.lang
        },
        userLoaded: true,
      };


    case "UPDATE_WORKING":
      return {
        ...state,
        user: {
          ...state.user,
          working: action.payload,
        },
      };

    case "UPDATE_AVATAR":
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.payload,
        },
      };

      case "UPDATE_LANGUAGE":
        return {
          ...state,
          user: {
            ...state.user,
            lang: action.payload,
          },
        };

      case "UPDATE_FACTORY":
        return {
          ...state,
          user: {
            ...state.user,
            selectedFactory: action.payload,
          },
        };
        
      case "UPDATE_SEARCH_OPEN":
          return {
            ...state,
            showSearch: action.payload
          };

      
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
