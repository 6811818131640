import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiSecure } from "../../components/api";
import { Loading, LoadingItem } from "../../components/Loading/Loading";
import "./Project.css";

import { useTranslate } from "../../components/Translate";


import { useMediaQuery } from "react-responsive";
import { getEnvSettings } from "../../config/config";

import { EnvironmentOutlined, EditOutlined } from '@ant-design/icons';

import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { EditContactModal } from "./EditContactModal";
import { EditAddressModal } from "./EditAddressModal";
import { EditScheduleModal } from "./EditScheduleModal";
import { EditReceiverModal } from "./EditReceiverModal";



const contacts = [
  { id: 1, title: 'Tilaaja', name: 'testi ukko', phone: '123 345 567', email: "testi@gmail.com" },
  { id: 2, title: 'Projektipäällikkö', name: 'Jaska ukko', phone: '123 345 567', email: "testi@gmail.com" },
  { id: 3, title: 'Projektipäällikkö', name: 'Jaska ukko', phone: '123 345 567', email: "testi@gmail.com" },
  { id: 4, title: 'Projektipäällikkö', name: 'Jaska ukko', phone: '123 345 567', email: "testi@gmail.com" },
  { id: 5, title: 'Projektipäällikkö', name: 'Jaska ukko', phone: '123 345 567', email: "testi@gmail.com" },
  { id: 6, title: 'Projektipäällikkö', name: 'Jaska ukko', phone: '123 345 567', email: "testi@gmail.com" },
  { id: 7, title: 'Projektipäällikkö', name: 'Jaska ukko', phone: '123 345 567', email: "testi@gmail.com" },

]


export const ProjectInfo = ({ navigate, projectData, getProject }) => {
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isMediumWidth = useMediaQuery({ maxWidth: 1100 });

  const API_KEY = process.env.REACT_APP_MAPS_API_KEY


  /* 
  https://*.ybt.fi/*
  http://localhost/*
  http://127.0.0.1/*
  localhost:3000
  http://localhost:3000/project/3788
  */

  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [editContactData, setEditContactData] = useState({});

  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);

  const [isEditScheduleModalOpen, setIsEditScheduleModalOpen] = useState(false);
  const [isEditReceiverModalOpen, setIsEditReceiverModalOpen] = useState(false);

  const [isMapReady, setIsMapReady] = useState(false);
  const [coords, setCoords] = useState({ lat: 66.67, lng: 26.86, isDefault: true }); //Defaults to finland 




  useEffect(() => {
    console.log('projectData222', projectData?.tiedot)
    if (projectData?.tiedot) {
      if (!projectData?.tiedot?.lat || !projectData?.tiedot?.lng) {
        setIsMapReady(true)
      } else {
        var newlat = parseFloat(projectData?.tiedot?.lat.replace(/,/g, "."));
        var newlng = parseFloat(projectData?.tiedot?.lng.replace(/,/g, "."));

        setCoords({ lat: newlat, lng: newlng, isDefault: false })
        setIsMapReady(true)
      }
    }
  }, [projectData]);



  useEffect(() => {
    console.log('coords222', coords)
  }, [coords]);

  const updateAddress = (address1, address2) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateProjectAddress`,
      data: {
        projectId: projectData?.tiedot?.Tyokohdenro,
        address1: address1,
        address2: address2,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        getProject()
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const updateReceiver = (recText) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateProjectReceiver`,
      data: {
        projectId: projectData?.tiedot?.Tyokohdenro,
        recText: recText,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        getProject()
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const updateSchedule = (schedText) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateProjectSchedule`,
      data: {
        projectId: projectData?.tiedot?.Tyokohdenro,
        schedText: schedText,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        getProject()
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };



  const updateContact = (id, data) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateContact`,
      data: {
        projectId: projectData?.tiedot?.Tyokohdenro,
        contactId: id,
        data: data,
      },
    };
    console.log("updateContact", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        getProject()
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };



  return (<div className={`projectpage-content`}>


    <EditContactModal
      isOpen={isEditContactModalOpen}
      closeModal={() => {
        setIsEditContactModalOpen(false);
      }}
      data={editContactData}
      saveFunc={updateContact}
    />

    <EditAddressModal
      isOpen={isEditAddressModalOpen}
      closeModal={() => {
        setIsEditAddressModalOpen(false);
      }}
      data={{
        address1: projectData?.tiedot?.Toimitusosoite1,
        address2: projectData?.tiedot?.Toimitusosoite2,
      }}
      saveFunc={updateAddress}
    />


    <EditScheduleModal
      isOpen={isEditScheduleModalOpen}
      closeModal={() => {
        setIsEditScheduleModalOpen(false);
      }}
      data={{
        schedule: projectData?.tiedot?.Aikataulu,
      }}
      saveFunc={updateSchedule}
    />

    <EditReceiverModal
      isOpen={isEditReceiverModalOpen}
      closeModal={() => {
        setIsEditReceiverModalOpen(false);
      }}
      data={{
        receiver: projectData?.tiedot?.Vastaanottaja,
      }}
      saveFunc={updateReceiver}
    />


    <div className="project-info">
      <p className="project-info-title font-dm-bold">Projektin tiedot</p>


      <div className="project-info-box">


        {/* YHTEYSTIEDOT */}
        <div className="project-info-contacts">
          <p className="project-info-box-header font-dm-bold">Yhteystiedot</p>

          {projectData?.yhteystiedot?.length >= 1 && projectData?.yhteystiedot?.map((contact) => {

            if (contact.OnkoTilaaja) {
              contact.Toimenkuva = "Tilaaja"
            }

            return (
              <div className="project-info-contacts-item" key={contact.id}>
                <div className="project-info-row">
                  <p className={`project-info-contacts-item-header ${contact?.Toimenkuva ? 'font-dm-regular' : 'font-dm-bold'}`}>{contact?.Toimenkuva ? contact?.Toimenkuva : contact?.Henkilo}</p>
                  <div className="project-info-edit project-info-marginleft" onClick={() => {
                    console.log('contact', contact);
                    setEditContactData(contact)
                    setIsEditContactModalOpen(true)
                  }}>
                    <EditOutlined />
                  </div>
                </div>


                {contact?.Toimenkuva ? <p className="font-dm-bold">{contact?.Henkilo}</p> : null}
                <p className="font-dm-regular">{contact?.GSM}</p>
                <p className="font-dm-regular">{contact?.Sahkoposti}</p>
              </div>
            )

          })}
        </div>



        <div className="project-info-box2-inner">

          {/* TOIMITUS */}
          <div className="project-info-delivery">
            <p className="project-info-box-header font-dm-bold">Toimitus</p>

            <div className="project-info-row">
              <div className="project-info-delivery-item">
                <div className="project-info-row">
                  <p className="project-info-delivery-item-header font-dm-regular">Toimitusosoite</p>
                  <div className="project-info-edit" onClick={() => {
                    setIsEditAddressModalOpen(true);
                  }}>
                    <EditOutlined />
                  </div>
                </div>
                <p className="project-info-delivery-item-value font-dm-bold">{projectData?.tiedot?.Toimitusosoite1 ? projectData?.tiedot?.Toimitusosoite1 : ''}</p>
                <p className="project-info-delivery-item-value font-dm-bold">{projectData?.tiedot?.Toimitusosoite2 ? projectData?.tiedot?.Toimitusosoite2 : ''}</p>
              </div>

              <div className="project-info-delivery-item">
                <div className="project-info-row">
                  <p className="project-info-delivery-item-header font-dm-regular">Vastaanottaja</p>
                  <div className="project-info-edit" onClick={() => { setIsEditReceiverModalOpen(true); }}>
                    <EditOutlined />
                  </div>
                </div>
                <p className="project-info-delivery-item-value font-dm-bold">{projectData?.tiedot?.Vastaanottaja ? projectData?.tiedot?.Vastaanottaja : ''}</p>
              </div>
            </div>



            <div className="project-info-delivery-map">
              {isMapReady ?
                <APIProvider apiKey={API_KEY}>
                  <Map
                    style={{ width: '100%', height: '100%' }}
                    defaultCenter={{ lat: coords?.lat, lng: coords?.lng }}
                    defaultZoom={coords?.isDefault ? 3 : 10}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}

                  >
                    {!coords?.isDefault && <Marker position={{ lat: coords?.lat, lng: coords?.lng }} />}
                  </Map>
                </APIProvider>
                : null}
            </div>

          </div>

          {/* AIKATAULU */}
          <div className="project-info-schedule">
            <p className="project-info-box-header font-dm-bold">Aikataulu</p>
            <div className="project-info-row">
              <p className="project-info-schedule-value font-dm-regular">{projectData?.tiedot?.Aikataulu ? projectData?.tiedot?.Aikataulu : '-'}</p>
              <div className="project-info-edit project-info-marginleft" onClick={() => { setIsEditScheduleModalOpen(true); }}>
                <EditOutlined />
              </div>
            </div>
          </div>
        </div>


      </div>


      {/*       <Row gutter={[16, 16]}>


        <Col xs={24} md={8}>
          <Card bordered={false} style={{ minHeight: '200px' }}>
            <Title level={4}>Yhteystiedot</Title>
            <Text strong>Tilaaja</Text>
            <div>
              Sukunimi Etunimi<br />
              +358 500 696695<br />
              sähköposti@yritys.fi
            </div>
            <Divider />
            <Text strong>Projektipäällikkö</Text>
            <div>
              Sukunimi Etunimi<br />
              +358 500 696695<br />
              sähköposti@yritys.fi
            </div>
          </Card>
        </Col>

        <Col xs={24} md={16}>
          <Card bordered={false} style={{ minHeight: '200px' }}>
            <Row gutter={[16, 16]} align="middle">
              <Col xs={12}>
                <div>
                  <Text strong>Toimitusosoite </Text>
                  <EditOutlined style={{ marginLeft: 8 }} />
                  <div>Tehdastie 94, 94200 Kemi</div>
                </div>
              </Col>
              <Col xs={12}>
                <div>
                  <Text strong>Vastaanottaja </Text>
                  <EditOutlined style={{ marginLeft: 8 }} />
                  <div>Vapaa teksti</div>
                </div>
              </Col>
            </Row>
            <Divider />
            <div style={{ height: '200px', background: '#e0e0e0' }}>
              <EnvironmentOutlined style={{ fontSize: '48px', color: '#8c8c8c', margin: '0 auto', display: 'block' }} />
            </div>
          </Card>


          <Card bordered={false} style={{ marginTop: '16px' }}>
            <Row>
              <Col span={24}>
                <Text strong>Aikataulu </Text>
                <EditOutlined style={{ marginLeft: 8 }} />
                <div>1. krs VKO 35, 2. krs VKO 37</div>
              </Col>
            </Row>
          </Card>

        </Col>
      </Row> */}


    </div>

  </div>

  );
};
