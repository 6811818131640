import "./LanguageSwitcher.css";
import { useDispatch, useSelector } from "react-redux";
import AllActions from "../../store/actions";
import { useTranslate } from "../Translate";
import { Dropdown, Menu } from "antd";
import { useState } from "react";



export function LanguageMenu() {
  const { locale } = useTranslate();
  const { lang } = useSelector((state) => state.currentUser.user);

  let label = ''

  if (lang === 'fi') label=locale.finland
  if (lang === 'se') label=locale.sweden
  if (lang === 'en') label=locale.english

  return ([{
      label: (
        <span className="displayValue font-dm-medium">
          {label}
        </span>
      ),
      key: "languages",
      children:     [
        {
          label: (
            <p className="menuValue font-dm-medium">
              {locale.finland}
            </p>
          ),
          key: 'fi',
        },
        {
          label: (
            <p className="menuValue font-dm-medium">
              {locale.sweden}
              </p>
          ),
                 key: 'se',
        },
        {
          label: (
            <p className="menuValue font-dm-medium">
              {locale.english}
              </p>
          ),
                 key: 'en',
        },
      ]
    }])
}



export function LanguageSwitcher({ color, additionalClasses = '' }) {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.currentUser.user);

  const [menuVisible, setMenuVisible] = useState(false);

  const updateLang = (nLang) => {
    dispatch(AllActions.userActions.updateLang(nLang));
  }

  const onClick = ({ key }) => {
    console.log(`Click on item ${key}`);
    updateLang(key);
    setMenuVisible(false);
  };

  return (
    <Dropdown
      className="lngDropdown"
      menu={{ items: LanguageMenu()[0].children, onClick }}
      trigger={["click"]}
      open={menuVisible}
      onOpenChange={(visible) => {
        setMenuVisible(visible);
      }}
      placement="bottomRight"
    >
      <p className="displayValue font-dm-medium">
        {lang === 'fi' ? 'FI' : ''}
        {lang === 'se' ? 'SE' : ''}
        {lang === 'en' ? 'EN' : ''}
      </p>
    </Dropdown>
  );
}
