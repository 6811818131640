import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Schedule.css";

import Timeline, { DateHeader, TimelineHeaders } from "react-calendar-timeline";

// make sure you include the timeline stylesheet or the timeline will not be styled
import moment from "moment";
import "moment/locale/fi";
import "react-calendar-timeline/lib/Timeline.css";

import { drawLine, DrawTooltip } from "./ScheduleSharedFunc";

export const TableView = ({ usersData, getUsersData, userLines, isScreenshot}) => {
  const TODAY = moment();
  const DAY = 1000 * 60 * 60 * 24;
  const WEEK = DAY * 7;
  const MONTH = WEEK * 4;

  const MIN_ZOOM = WEEK * 3;
  const MAX_ZOOM = MONTH * 6;

  //const [isLoading, setisLoading] = useState(false);

  //const [usersData, setUsersData] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const { selectedFactory } = useSelector((state) => state.currentUser.user);

  /*   const getUsersData = () => {
    setisLoading(true);
    setGroupsData([]);
    setUsersData([]);

    let req_config = {
      method: "POST",
      url: `/intra_getusersdata`,
      data: {
        factory: selectedFactory,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        //console.log("RESPONSE", response.data);
        setUsersData(response.data.data);
        setisLoading(false);
      })
      .catch((error) => {
        //console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getUsersData();
    //console.log("getUsersData called", selectedFactory);
  }, [selectedFactory]);
 */

  //Tables to Timeline
  useEffect(() => {

/*     console.log('table start', usersData)
    console.log('table start2', userLines, userLines.length > 0) */

    if ((usersData.tyontekijasisalto || usersData.lomat)) {
      let newGroups = [];
      let newItems = [];

      let datapoydat = usersData.poydat;

      let datasisalto = usersData.tyontekijasisalto;
      let datalomat = usersData.lomat;
      let datapyhat = usersData.arkipyhat;

      //Luodaan pöydät ja käyttäjät niiden alle
      if (datapoydat) {
        console.log("poydat", datapoydat);



        datapoydat.map((table, index) => {

          if (table.EiKaytossa) return

          let tableGroupId = "table_" + table.PoytaNro;

          newGroups.push({
            id: tableGroupId,
            title: table.Poyta,
            table: true,
            height: 80,
          });

          
          let userGroupCount = 0
          //console.log('table userlines1', userLines)


           Object.keys(userLines).map((userid, index) => {
            let userdata = userLines[userid]
            let userlines = userLines[userid].data

           // console.log('table userlines22', userlines)

            userlines.map((row, index2) => {
              if (table.PoytaNro != row.table) return;

              let userGroupId = tableGroupId + "_user_" + userdata.id;
  
              if (!newGroups.some((person) => person.id === userGroupId)) {
                userGroupCount = userGroupCount + 1
                newGroups.push({
                  id: userGroupId,
                  title: userdata.name,
                });
              }

              drawLine(
                newItems,
                userGroupId,
                row.title,
                row.start,
                row.end,
                row.type,
                row.data
              );

              drawLine(
                newItems,
                tableGroupId,
                " ",
                row.start,
                row.end,
                "table",
                {
                  data: {
                    name: table.Poyta,
                  },
                }
              );

             })
            })

            //Jos halutaan poistaa tyhjät rivit
            if (isScreenshot && userGroupCount == 0)  {
              newGroups = newGroups.filter((grpArr) => grpArr.id != tableGroupId);
            }

        });
      }

      console.log("table newGroups", newGroups);
      console.log("table newItems", newItems);



      if (isScreenshot && newGroups.length == 0)  {
        newGroups.push({
          id: 0,
          title: '',
        },
        {
          id: 1,
          title: '',
        },
        {
          id: 2,
          title: '',
        },
        
        );
      }


      setGroupsData(newGroups);
      setItemsData(newItems);
    }
  }, [usersData, userLines]);

  const groupRenderer = ({ group }) => {
    return (
      <>
        {group.table ? (
          <div className={`table-group ${group.table ? "table" : ""}`}>
            <span className="title font-dm-bold">{group.title}</span>
          </div>
        ) : (
          <div className={`custom-group ${group.table ? "table" : ""}`}>
            <span className="title font-dm-bold">{group.title}</span>
          </div>
        )}
      </>
    );
  };

  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    let itemProps = getItemProps({});
    //console.log("getItemProps:", itemProps.style);

    //Suoritetaan laskennat/muutokset korkeutta/ryhmittelyä varten

    if (
      item.type == "table" ||
      item.type == "project-end" ||
      item.type == "project-ended" ||
      item.type == "stage"
    ) {
      var top = parseFloat(itemProps.style.top.slice(0, -2));
      top = top + 35;
      itemProps.style.top = top + "px";
    }

    let tooltipTypes = {
      work: true,
      table: true,
    };

    let showTooltip = tooltipTypes[item.type];

    return (
      <>
        <div
          data-tip
          data-for={"tooltip-" + item.id}
          {...itemProps}
          onMouseDown={() => {
            //console.log("on item click", item);
          }}
        >
          <div
            style={{
              height: itemContext.dimensions.height,
              paddingLeft: 5,
              paddingRight: 3,
              /* 
              marginRight:5, */
              /*  width:'90%', */

              /*               display: "flex",
              justifyContent: "center", */
              borderRadius: 10,
            }}
          >
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                /*               borderWidth:1,
              borderColor:'red',
              borderStyle:'solid', */
              }}
              className="font-dm-regular"
            >
              {itemContext.title}
            </p>
          </div>
        </div>

        {showTooltip && (
          <DrawTooltip
            id={"tooltip-" + item.id}
            type={item.type}
            data={item.data}
          />
        )}
      </>
    );
  };

  /*   if (isLoading) {
    return <Loading />;
  } */

  return (
    <>
      <div className="tl-container">
        {groupsData && groupsData.length > 0 && (
          <div id={`capture-paikat`}>
            <Timeline /* resizeDetector={containerResizeDetector} */
              groups={groupsData}
              items={itemsData}
              defaultTimeStart={moment().hours(0)}
              defaultTimeEnd={moment().hours(0).add(4, "week")}
              /*               visibleTimeStart={moment().hours(0)}
              visibleTimeEnd={moment().hours(0).add(1, "year")} */
              canMove={false}
              canResize={false}
              canChangeGroup={false}
                /*   stackItems  */
              timeSteps={{
                second: 1,
                minute: 1,
                hour: 1,
                day: 1,
                month: 1,
                year: 1,
              }}
              sidebarWidth={220}
              lineHeight={50}
              itemRenderer={itemRenderer}
              groupRenderer={groupRenderer}
              /*   horizontalLineClassNamesForGroup={(group) => ["custom-group"]} */
              minZoom={MIN_ZOOM}
              maxZoom={MAX_ZOOM}
              onItemSelect={null}
              onItemClick={null}
            >
              <TimelineHeaders>
                {/*                 <SidebarHeader>
                  {({ getRootProps }) => {
                    return (
                      <div {...getRootProps()}>
                        <p className="timeline-left-header font-itc">
                          Tuotantopaikat
                        </p>
                      </div>
                    );
                  }}
                </SidebarHeader> */}
                <DateHeader unit="week" />
                <DateHeader />
              </TimelineHeaders>
            </Timeline>
          </div>
        )}
      </div>
    </>
  );
};
