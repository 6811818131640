import { getParsedDate } from "../../components/globalFunctions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Fragment, useEffect, useState } from "react";

import {
  SortDescendingOutlined,
  SortAscendingOutlined
} from "@ant-design/icons";
import { useTranslate } from "../../components/Translate";


// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
export const copy = (source, draggableId, destination, droppableDestination) => {
  console.log("COPYFUNC:");
  console.log("==> source", source);
  console.log("==> dest", destination);
  console.log("==> dropdest", droppableDestination);

  const destClone = Array.from(destination);

  destClone.splice(droppableDestination.index, 0, {
    id: draggableId,
  });

  console.log("==> CLONE:", destClone);

  return destClone;
};

export const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};



const ListHeaderItem = ({ name, isFilter, sortDirection, onClick, additionalClass }) => {
  return <p className={`font-dm-bold ${additionalClass ? additionalClass : ''}`} onClick={onClick}>{name} {isFilter && (sortDirection === "asc" ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}</p>
}


export const RenderListHeader = ({ isMobile = false, isMediumWidth = false, listType = null, tableFilter, setTableFilter, sortDirection, setSortDirection }) => {
  const { locale } = useTranslate();


  const handleSort = (filter) => {
    if (tableFilter === filter) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setTableFilter(filter);
      setSortDirection('asc');
    }
  };

  let listLast = {
    ["elementit"]:  locale.etap,
    ["etapit"]:  locale.factory,
    ["aikataulu"]: locale.waybill,
  }

  return (
    <div className="project-p-table-headers">
      <ListHeaderItem name={locale.tunnus} additionalClass={"table-first"} isFilter={tableFilter === "tunnus"} sortDirection={sortDirection} onClick={() => { handleSort("tunnus") }} />
      <ListHeaderItem name={locale.id} isFilter={tableFilter === "id"} sortDirection={sortDirection} onClick={() => { handleSort("id") }} />

      {!isMobile && !isMediumWidth && <>
        <ListHeaderItem name={locale.pituus} isFilter={tableFilter === "pituus"} sortDirection={sortDirection} onClick={() => { handleSort("pituus") }} />
        <ListHeaderItem name={locale.korkeus} isFilter={tableFilter === "korkeus"} sortDirection={sortDirection} onClick={() => { handleSort("korkeus") }} />
        <ListHeaderItem name={locale.paksuus} isFilter={tableFilter === "paksuus"} sortDirection={sortDirection} onClick={() => { handleSort("paksuus") }} />
      </>}

      {!isMobile && <ListHeaderItem name={locale.m2} isFilter={tableFilter === "m2"} sortDirection={sortDirection} onClick={() => { handleSort("m2") }} />}
      {!isMobile && <ListHeaderItem name={locale.paino} isFilter={tableFilter === "paino"} sortDirection={sortDirection} onClick={() => { handleSort("paino") }} />}

      {!isMobile && !isMediumWidth && <ListHeaderItem name={locale.piirustus} isFilter={tableFilter === "piirustus"} sortDirection={sortDirection} onClick={() => { handleSort("piirustus") }} />}

      <ListHeaderItem name={locale.valmistus} isFilter={tableFilter === "valmistus"} sortDirection={sortDirection} onClick={() => { handleSort("valmistus") }} />
      <ListHeaderItem name={locale.toimitus} isFilter={tableFilter === "toimitus"} sortDirection={sortDirection} onClick={() => { handleSort("toimitus") }} />

      {!isMobile && <ListHeaderItem name={listLast[listType]} isFilter={tableFilter === "lastcol"} sortDirection={sortDirection} onClick={() => { handleSort("lastcol") }} />}
    </div>
  );
};


export const RenderListFooter = ({ isMobile, isMediumWidth, total, m2, paino, piirustus, valmistus, toimitus }) => {

  return (
    <div className="project-p-table-footers">
      <p className="table-first font-dm-bold">
        {`${total || 0} kpl`}
      </p>
      <p className="font-dm-bold"></p>

      {!isMobile && !isMediumWidth && <>
        <p className="font-dm-bold"></p>
        <p className="font-dm-bold"></p>
        <p className="font-dm-bold"></p>
      </>}

      {!isMobile && <p className="font-dm-bold">
        {`${m2?.toFixed(1) || 0} m2`}
      </p>}
      {!isMobile && <p className="font-dm-bold">
        {`${paino?.toFixed(1) || 0} t`}
      </p>}

      {!isMobile && !isMediumWidth && <p className="font-dm-bold">
        {`${piirustus || 0} kpl`}
      </p>}

      <p className="font-dm-bold">
        {`${valmistus || 0} kpl`}
      </p>
      <p className="font-dm-bold">
        {`${toimitus || 0} kpl`}
      </p>

      {!isMobile && <>
        <p className="font-dm-bold"></p>
      </>}
    </div>
  );
};




const RenderListRow = ({ data = {}, itemsInDroppable = {}, dragDisabled = false, listType = null, dragHandle = {}, isMobile = false, isMediumWidth = false }) => {
 // console.log('RenderListRow', listType, data, itemsInDroppable) 

  let gray = false
  if (itemsInDroppable[data.ValmistusNro] && !dragDisabled) gray = true


  let listLast = {
    ["elementit"]: data.EtappiNimi,
    ["etapit"]: data.ValmistusTehdas,
    ["aikataulu"]: data.KuormakirjaNumero,
  }


  return (
    <div className={`project-p-table-values ${gray && 'inList'}`}>

      {!isMobile ?
        <div className={`draggableRowHandle`}  {...dragHandle}>
          <p className={`table-first font-dm-regular ${!gray && 'notList-left'} ${!dragDisabled && !gray && 'dragEnabled'}`}>{data.Tunnus} {data.Versio ? `(${data.Versio})` : ''}</p>
          <p className={`font-dm-regular ${!gray && 'notList-right'}  ${!dragDisabled && !gray && 'dragEnabled'}`}>{data.ValmistusNro}</p>
        </div>
        : <>
          <p className={`table-first font-dm-regular`}>{data.Tunnus}</p>
          <p className={`font-dm-regular`}>{data.ValmistusNro}</p>
        </>}


        {!isMobile && !isMediumWidth && <>
          <p className="font-dm-regular">{data.Pituus}</p>
          <p className="font-dm-regular">{data.Korkeus}</p>
          <p className="font-dm-regular">{data.Paksuus}</p>
        </>}


        {!isMobile &&  <p className="font-dm-regular">{data.M2.toFixed(1)}</p>}
        {!isMobile &&  <p className="font-dm-regular">{data.Paino.toFixed(1) + " t"}</p>}


        {!isMobile && !isMediumWidth && <p className="font-dm-regular">{getParsedDate(data.Piirustus)}</p>}

        <p className="font-dm-regular">{getParsedDate(data.ValmistusPVM)}</p>
        <p className="font-dm-regular">{getParsedDate(data.ToimitusPVM)}</p>

        {!isMobile && <>
          <p className="font-dm-regular">{listLast[listType]}</p>
        </>}

    </div>
  );
};

function getStyle(style, snapshot, id) {
  /* console.log('getStyle', style, snapshot, id) */

  if (style.transform == "translate(0px, 28px)") {
    style.transform = null;
  }

  if (snapshot.isDropAnimating) {
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.40s`,
    };
  }

  return style;
}

export const DraggableRowItem = ({ index = null, item = null, itemId = null, listType = null, dragDisabled = false, itemsInDroppable = {}, isMobile = false, isMediumWidth = false }) => {
/*     console.log('DraggableRowItem', index, dragDisabled, itemId)
 */    if (!itemId) return
  return (
    <Draggable
      key={itemId}
      draggableId={itemId.toString()}
      index={index}
      isDragDisabled={dragDisabled}
    >
      {(provided, snapshot) => {

        return (
          <Fragment>
            <div
              className="dragtest"
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={getStyle(provided.draggableProps.style, snapshot, itemId)}
            >
              {snapshot.isDragging ? (
                <div className="draggableCustomRow"><p className="draggableCustomRow-1">{item.Tunnus}</p> <p className="draggableCustomRow-2">{item.ValmistusNro}</p></div>
              ) : (
                <RenderListRow data={item} itemsInDroppable={itemsInDroppable} dragDisabled={dragDisabled} listType={listType} dragHandle={provided.dragHandleProps} isMobile={isMobile} isMediumWidth={isMediumWidth} />
              )}
            </div>

            {snapshot.isDragging && (
              <>
                <div className="draggableRow">
                  <RenderListRow data={item} itemsInDroppable={itemsInDroppable} dragDisabled={dragDisabled} listType={listType} isMobile={isMobile} isMediumWidth={isMediumWidth} />
                </div>
              </>
            )}
          </Fragment>
        );
      }}
    </Draggable>
  );
};
