import userActions from "./userActions";
import projectActions from "./projectActions";

const AllActions = {
  userActions,
  projectActions,
};

export default AllActions;



