import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Modal from "react-modal";

import { DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const { Option } = Select;

const ModalHeader = ({ closeModal, header, user }) => {
  return (
    <div>
      <div className="proj-modalHeaderRow">
        <p className="proj-modalHeader font-dm-bold">{header}</p>
        <CloseOutlined
          className="proj-modalHeaderClose"
          onClick={() => closeModal()}
        />
      </div>
      <p className="proj-modalHeaderUser font-dm-medium">{user}</p>
    </div>
  );
};

const SaveBtn = ({ onClick }) => {
  return (
    <div className="proj-saveButtonCont">
      <button className="proj-saveButton font-dm-regular" onClick={onClick}>
        Tallenna
      </button>
    </div>
  );
};

export const NewStage = ({ isOpen, closeModal, data, stages, saveFunc }) => {
 // console.log("SelectStageDate data", data);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [baseStage, setBaseStage] = useState('base');
  const [name, setName] = useState('');

  useEffect(() => {
    console.log("isOpen updated", isOpen);

    if (isOpen) {
      setStartDate(null)
      setEndDate(null)
      setBaseStage('base')
    }

  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        className="workmodalBase"
        style={{
          overlay: {
            zIndex: 100,
            backgroundColor: "rgba(70, 70, 70, 0.5)",
          },
        }}
        ariaHideApp={false}
      >
        <div className="proj-modalContainer">
          <div className="proj-modal">
            <ModalHeader
              closeModal={() => closeModal()}
              header={"Etapin lisäys"}
              user={""}
            />

            <p className="font-dm-medium label">Juuritaso:</p>

            <Select
              defaultValue="0"
              style={{ width: 150 }}
              className="picker value"
              value={baseStage}
              onChange={(value, option) => {
                console.log("PICKER CHANGE", value);

                setBaseStage(value)


/*                 let selected = stages.find(
                  (row) => row.EtappiNro === value
                );

                console.log("selected", selected);
 */
              }}
            >
              <Option value={'base'}>Juuritaso</Option>

              {stages.map((item, index) => {
                if (item.ParentID != 0) return;
                return (
                  <Option key={index + 1} value={item.EtappiNro}>
                    {item.Etappi}
                  </Option>
                );
              })}
            </Select>

            <p className="font-dm-medium label">Nimi</p>

            <Input
             value={name}
             onChange={(text) => {
              setName(text.target.value)
              console.log(text.target.value)
            }}
            />


            <p className="font-dm-medium label">Valitse toimituksen alku/loppu</p>

            <RangePicker
              className="rangepicker"
              size="small"
              showWeek={true}
              suffixIcon={null}
              value={[startDate, endDate]}
              format={'DD.MM.YYYY'}
              onChange={(value) => {
                //console.log("onChange", value, dayjs(value[0]).format("YYYY-MM-DD"), dayjs(value[1]).format("YYYY-MM-DD"));
                setStartDate(value[0]);
                setEndDate(value[1]);
              }}
              onOk={() => {
                console.log("onOk");
              }}
            />

            <SaveBtn
              onClick={() => {
                closeModal();
                saveFunc(baseStage, name,  startDate ? dayjs(startDate).format("YYYY-MM-DD") : null, endDate ? dayjs(endDate).format("YYYY-MM-DD"):null)
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
