import { useCallback, useEffect, useState } from "react";
import { apiSecure } from "../../components/api";
import { Loading } from "../../components/Loading/Loading";
import "./MapPage.css";

import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "../../components/Translate";
import { useDispatch, useSelector } from "react-redux";
import { getEnvSettings } from "../../config/config";


import { AdvancedMarker, APIProvider, InfoWindow, Map, Marker, Pin, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';

const API_KEY = process.env.REACT_APP_MAPS_API_KEY

export const MapPage = ({ setToken }) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);

  const { locale } = useTranslate();
  const navigate = useNavigate();
  const envConfig = getEnvSettings();


  console.log('TESTIENV', process.env.MAPS_API_KEY)


  const { selectedFactory } = useSelector(
    (state) => state.currentUser.user
  );

  const [worksData, setWorksData] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  // clicking a marker will set it as selected and show the infowindow
  const handleMarkerClick = useCallback((markerData) => {
    setSelectedMarker(markerData);
    setInfoWindowShown(true);
  }, []);

  // if the maps api closes the infowindow, we have to synchronize our state
  const handleClose = useCallback(() => {
    setInfoWindowShown(false);
    setSelectedMarker(null);
  }, []);

  const getMapProjects = () => {
    setisLoading(true);
    console.log("getMapProjects");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getMapProjects`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        tehdas: selectedFactory,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        setWorksData(response?.data?.data?.kohteet);
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getMapProjects();
    //console.log("useEffect called");
  }, [selectedFactory]);

  useEffect(() => {
    console.log("worksData", worksData);
  }, [worksData]);

  if (isLoading) {
    return <Loading />;
  }

  const factories = [
    { id: 1, lat: 66.33688697623289, lng: 23.70194655691215, color: "black", text: 'Tehdas 1'},
    { id: 2, lat: 64.12860496223296, lng: 29.478135299199256, color: "blue", text: 'Tehdas 2' },
    { id: 3, lat: 65.3555, lng: 21.37479, color: "yellow", text: 'Tehdas 3' },
    { id: 4, lat: 64.92375, lng: 19.461, color: "white", text: 'Tehdas 4' },
  ];

  return (
    <div className="map-page-main">
          <div className="map-page-inner">

      <APIProvider apiKey={API_KEY}>
        <Map
          mapId={"a5c9eab71d5a0001"}
          style={{ width: "100%", height: "100%", borderRadius:"14px" }}
          defaultCenter={{ lat: 66.67, lng: 26.86 }} //Suomi
          defaultZoom={5}
          gestureHandling={"greedy"}
          disableDefaultUI={false}
        >
          {factories.map((item) => (
            <AdvancedMarker
              key={item.id}
              position={{ lat: item.lat, lng: item.lng }}
              onClick={() => handleMarkerClick(item)}
            >
              <Pin
                background={item.color}
                borderColor={"#006425"}
                glyphColor={"#60d98f"}
              />
            </AdvancedMarker>
          ))}

          {worksData.map((item) => {
            if (!item.lat || !item.lng) return null;

            var newlat = parseFloat(item.lat.replace(/,/g, "."));
            var newlng = parseFloat(item.lng.replace(/,/g, "."));

            console.log("item", newlat, newlng);

            return (
              <Marker
                key={item.Tyokohdenro}
                position={{ lat: newlat, lng: newlng }}
                onClick={() => handleMarkerClick(item)}
              />
            );
          })}

          {infoWindowShown && selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
              onClose={handleClose}
            >
              <h2>TESTI</h2>
              <p>{selectedMarker.text}</p>
            </InfoWindow>
          )}
        </Map>
      </APIProvider>
      </div>
    </div>
  );
};
