import { api, apiSecure } from "../../components/api";
import { getEnvSettings } from "../../config/config";

//GetToken for apicalls etc
const getToken = async () => {
  const credentials = 'as' //await SecureStore.getItemAsync("USER_AUTH");

  if (credentials) {
    const myJson = JSON.parse(credentials);
    //console.log("GetTokenasd", myJson.token);
    return myJson.token;
  }

  return false;
};

//Check if user are "loggedin"
const loadUser = () => async (dispatch) => {
  //Language
  const locale = localStorage.getItem('locale')
  console.log('loadUser locale', locale)

  const envConfig = getEnvSettings();


  if (locale) {
    dispatch({
      type: "UPDATE_LANGUAGE",
      payload: locale
    });
  }

  

  //Auth
  //const token = await SecureStore.getItemAsync("AUTH_KEY");
  const credentials = JSON.parse(localStorage.getItem('USER_AUTH'))
  console.log("loadUser credentials", credentials);
  if (credentials && credentials.authenticated) {

      //Get User Data
      //console.log("loadUser requestGetUser");

      let req_config = {
        method: "POST",
        url: `${envConfig.apiUrlPrefix}refreshuser`,
      };

      const response = await apiSecure.request(req_config).catch((error) => {
        console.log("Load user error", error);
      });

      
      console.log('refreshuser Response:', response)

      if (!response) {
        console.log('TEST%%')
        await localStorage.setItem('USER_AUTH', JSON.stringify({
          accessToken: null,
          refreshToken: null,
          authenticated: false,
        }));

        dispatch({
          type: "USER_LOADED",
        });

        return;
      }

      const { data } = response;
      console.log("loadUser2 RESP", data);

      dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          userid: data.user.id,
          tunnus: data.user.tunnus,
          name: data.user.name,
          yritys: data.user.yritys,
          tehtaat: data.user.tehtaat,
        },
      });

  } else {
    dispatch({
      type: "USER_LOADED",
    });
  }
};

//Try to login
const getUser = (userObj) => async (dispatch) => {
  const { username, password } = userObj;
  const envConfig = getEnvSettings();

  console.log('getUser', userObj)

  dispatch({
    type: "LOGIN_START"
  });

  let req_config = {
    method: "POST",
    url: `${envConfig.apiUrlPrefix}login`,
    data: {
      email: username,
      password: password,
    },
  };

  const response = await api.request(req_config).catch(async (e) => {
    console.log("[getUser] catch((e)", e);
    //Alert.alert('Error!','Ei yhteyttä rajapintaan!')
  });

  console.log('getUser response', response)


  if (!response) {
    dispatch({
      type: "LOGIN_STOP"
    });

    return
  }

  const { data } = response;
  if (data.message === "user_loggedin") {
    //Data lokaalisti phoneen niin ei tarvitse joka kerta kirjautua uudelleen

    await localStorage.setItem('USER_AUTH', JSON.stringify({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      authenticated: true,
    }));

    const credentials = JSON.parse(localStorage.getItem('USER_AUTH'))

    console.log("getUser", credentials);

    dispatch({
      type: "LOGIN_SUCCESS",
        payload: {
          userid: data.user.id,
          tunnus: data.user.tunnus,
          name: data.user.name,
          yritys: data.user.yritys,
          tehtaat: data.user.tehtaat,
      },
    });
  } else {
    dispatch({
      type: "LOGIN_FAILED",
      payload: data.message,
    });
  }

  dispatch({
    type: "LOGIN_STOP"
  });
};




const logOut = () => async (dispatch) => {
  console.log("LOG_OUT Logging user out");
  //Tyhjennetään phonedata
  const envConfig = getEnvSettings();

  const credentials = JSON.parse(localStorage.getItem('USER_AUTH'))

  if (credentials) {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}logout`,
      data: {
        refreshToken: credentials.refreshToken,
      },
    };

    api(req_config)
    .then((response) => {
      console.log("RESPONSE FROM LOGOUT", response.data);
    })
    .catch((error) => {
      console.log("Error:", error);
    });
  }


  await localStorage.setItem('USER_AUTH', JSON.stringify({
    accessToken: null,
    refreshToken: null,
    authenticated: false,
  }));

  dispatch({
    type: "LOG_OUT",
  });
};




const updateAvatar = (avatarObj) => async (dispatch) => {
  console.log("updateAvatar");
  const { base64 } = avatarObj;
  if (!base64) return
  dispatch({
    type: "UPDATE_AVATAR",
    payload: base64
  });
};

const updateLang = (langObj) => async (dispatch) => {
  console.log("updateLang", langObj);
  if (!langObj) return

  await localStorage.setItem('locale', langObj) 

  dispatch({
    type: "UPDATE_LANGUAGE",
    payload: langObj
  });
};

const updateFactory = (facObj) => async (dispatch) => {
  console.log("updateFactory", facObj);
  if (!facObj) return

  //await localStorage.setItem('locale', facObj) 
  dispatch({
    type: "UPDATE_FACTORY",
    payload: facObj
  });
};


const getWorkState = () => async (dispatch) => {
  console.log("getWorkState");

  let req_config = {
    method: "GET",
    url: `/getWorkState`,
  };

  const response = await apiSecure.request(req_config).catch((error) => {
    console.log("getWorkState error", error);
    return
  });

  const { data } = response;
  if (!data) return 

  console.log(data)

  dispatch({
    type: "UPDATE_WORKING",
    payload: data.data
  });
};

const updateShowSearch = (facObj) => async (dispatch) => {
  console.log("updateShowSearch", facObj);
  //if (!facObj) return

  //await localStorage.setItem('locale', facObj) 
  dispatch({
    type: "UPDATE_SEARCH_OPEN",
    payload: !facObj
  });
};


export default {
  getUser,
  logOut,
  loadUser,
  getToken,
  updateAvatar,
  updateLang,
  getWorkState,
  updateFactory,
  updateShowSearch
};
